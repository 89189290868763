import { FaqCategory } from '../../../models/faq/faq-category.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Faq } from 'src/app/models/faq/faq.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.css']
})
export class FaqDetailComponent implements OnInit {
  @ViewChild('auto') auto;
  environment = environment;
  id: any = null;
  model = "faqs";

  record: Faq;
  faqCategories: FaqCategory;
  newForm: FormGroup;
  submitted = false;
  myControl = new FormControl();

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '200px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
    this.getCategories();
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Faq) => {
        this.record = res;
        console.log("this.record",this.record);
        this.setNewForm();
      })
    }
  }

  getCategories()
  {
    this.api.get('list/faqCategories').subscribe( (data : FaqCategory) => 
      {
        console.log('data', data);
        this.faqCategories = data;

      })
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      question: new FormControl('', [Validators.required]),
      answer: new FormControl('', [Validators.required]),
      faq_category_id: new FormControl('', [Validators.required]),
    });
  }

  private setNewForm() {
    this.newForm.get("question").setValue(this.record.question);
    this.newForm.get("answer").setValue(this.record.answer);
    this.newForm.get("faq_category_id").setValue(this.record.faq_category_id);

  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('question', this.f.question.value);
    recordToSave.append('answer', this.f.answer.value);
    recordToSave.append('faq_category_id', this.f.faq_category_id.value);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

}
