import { ProblematicCategory } from '../../../models/problematic/problematic-category.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Problematic } from '../../../models/problematic/problematic.model';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'app-problematic-detail',
  templateUrl: './problematic-detail.component.html',
  styleUrls: ['./problematic-detail.component.css']
})
export class ProblematicDetailComponent implements OnInit {
  @ViewChild('auto') auto;
  environment = environment;
  id: any = null;
  model = "problematics";

  record: Problematic;
  problematicCategories: ProblematicCategory[] = [];
  tags: Tag[] = [];
  tagsAssociated: any[] = [];
  filteredTags: any[] = [];
  newForm: FormGroup;
  submitted = false;
  myControl = new FormControl();
  keyword = "name";
  singledropdownSettings = {};
  selectedProblematicCategory = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getTags();
    this.singledropdownSettings = {
      singleSelection: true,
      textField: 'name',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Problematic) => {
        this.record = res;
        console.log("this.record",this.record);
        this.filteredTags = res.tags;
        this.tagsAssociated = res.tags.map(option => option.id);
        this.setNewForm();
      })
    }
  }

  getTags() {
    this.api.get("list/tags").subscribe((res: Tag[]) => {
      this.tags = res;
      console.log("this.tags",this.tags);
      this.getProblematicCategories();
    })
  }

  getProblematicCategories() {
    this.api.get("list/problematicCategories").subscribe((res: Tag[]) => {
      this.problematicCategories = res;
      console.log("this.problematicCategories",this.problematicCategories);
      this.getRecord();
    })
  }

  selectTag(item) {
    console.log("selectEvent", item)
    if(this.tagsAssociated.indexOf(item.id) === -1){
      this.tagsAssociated.push(item.id);
      this.filteredTags.push(item);
    }
    this.auto.clear();
  }

  removeTag(tag) {
    this.tagsAssociated = this.filteredTags.map(option => option.id).filter(option => option != tag.id);
    this.filteredTags = this.filteredTags.filter(option => option != tag);
  }

  selectFileCategory(item: any, type: any) {
    if(type === 'select'){
      this.selectedProblematicCategory.push(item);
    } else {
      this.selectedProblematicCategory = this.selectedProblematicCategory.filter(function(value) { return value.name != item.name;})
    }
    const index = this.problematicCategories.map(function(e) { return e.name; }).indexOf(item.name);
    this.newForm.get("problematic_category_id").setValue(this.problematicCategories[index].id);
  }
  
  private initNewForm() {

    this.newForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      user_equivalent: new FormControl('', [Validators.required]),
      problematic_category_id: new FormControl('', [Validators.required]),
      selectedProblematicCategory: new FormControl('', []),
    });
  }

  private setNewForm() {

    if(this.record.problematic_category_id){
      const index = this.problematicCategories.map(function(e) { return e.id; }).indexOf(this.record.problematic_category_id);
      this.selectedProblematicCategory.push({id:this.problematicCategories[index].id, name:this.problematicCategories[index].name});
      this.newForm.get("selectedProblematicCategory").setValue(this.selectedProblematicCategory);
    }

    this.newForm.get("name").setValue(this.record.name);
    this.newForm.get("user_equivalent").setValue(this.record.user_equivalent);
    this.newForm.get("problematic_category_id").setValue(this.record.problematic_category_id);
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('name', this.f.name.value);
    recordToSave.append('user_equivalent', this.f.user_equivalent.value);
    recordToSave.append('problematic_category_id', this.f.problematic_category_id.value);
    recordToSave.append('tags', this.tagsAssociated.join(","));
    console.log("this.tagsAssociated", this.tagsAssociated.join(","));

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

}
