<!-- <div class="row mb-3">
    <div class="col-md-12">
        <a class="btn btn-primary btn-rounded float-right" [routerLink]="'/' + url + '/create'">Créer une catégorie</a>
    </div>
</div> -->
<div class="row">
    <div class="col-md-12">
    <!--- Complete Table -->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <label for="exampleSelect1">Filtrer par abonnement</label>
                    <select class="form-control" id="exampleFormControlSelect1" (change)="filterIndividuals($event.target.value)">
                        <option value="">Tout les abonnements</option>
                        <option *ngFor="let member of membership?.data" value="{{member.title}}">{{ member.title }}</option>
                    </select>
                </div>
                <div class="col">
                    <label for="exampleSelect1">Rechercher par nom, prénom ou email</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="nom, prénom, email" #term>
                        <i class="fa fa-times" (click)="term.value=''; getAll()" style="position: absolute; right: 105px; top: 10px;"></i>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="search(term.value)">Rechercher</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped border mt-4">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nom, Prénom</th>
                            <th scope="col">Abonnement</th>
                            <th scope="col">Date</th>
                            <th scope="col">Consultations</th>
                            <th scope="col" class="text-right col-actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of records?.data">
                            <td>{{ record.id }}</td>
                            <td class="img-radius align-top">
                                <img class="mr-2 rounded-circle float-left" src="{{ environment.uploadPath }}users/{{ record.image }}"
                                  alt="" width="60px" height="60px">
                                <div>
                                  <h5 class="m-b-0 font-16 font-medium">{{ record.lastname }} {{ record.firstname }}</h5>
                                  <span>{{ record.email }}</span><br />
                                </div>
                            </td>
                            <td>
                                inscrit le : {{ record.created_at | date:'d/MM/yyyy' }}<br/>
                                vérifié le : {{ record.email_verified_at | date:'d/MM/yyyy' }}
                            </td>
                            <td><a class="btn" >{{ record.membership?.title }}</a></td>
                            <td></td>
                            <td align="right">
                                <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="edit(record.id)"><i class="icon-pencil"></i></button>
                                <button type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2" (click)="delete(record.id)"><i class="icon-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true" (pageChange)="getAll($event)"></ngb-pagination>
            </div>
        </div>
    </div>
</div>