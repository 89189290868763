import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Resource } from 'src/app/models/resource/resource.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.css']
})
export class ResourceDetailComponent implements OnInit {

  @ViewChild('file') file: ElementRef<HTMLElement>;
  environment = environment;
  id: any = null;
  model = "files";

  resource: Resource;

  selectedFilePath: any;
  imageToUpload: any = null;
  fileToUpload: any;
  newForm: FormGroup;
  memberships: any;
  fileCategories: any;

  types = [
    {code: 'link',name: "lien url"},
    {code: 'video', name: "lien video"},
    {code: 'file', name: "fichier"},
  ];

  submitted = false;
  
  singledropdownSettings = {};
  singledropdownMembershipsSettings = {};
  singledropdownTypesSettings = {};
  
  selectedFileCategory = [];
  selectedType = [];
  selectedMembership = [];

  selectedTextWrapper = 1;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '200px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getMemberships();
    this.setDropdownList();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Resource) => {
        this.resource = res;
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.resource.image;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+image';
    }
  }

  getMemberships() {
    this.api.get('list/memberships').subscribe((data) => {
      this.memberships = data;
      this.getFileCategories();
    })
  }

  getFileCategories() {
    this.api.get('list/fileCategories').subscribe((data) => {
      this.fileCategories = data;
      console.log("this.fileCategories", this.fileCategories)
      this.getRecord();
    })
  }
  
  setDropdownList() {
    this.singledropdownSettings = {
      singleSelection: true,
      textField: 'name',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
    this.singledropdownMembershipsSettings = {
      singleSelection: true,
      textField: 'title',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
    this.singledropdownTypesSettings = {
      singleSelection: true,
      idField: 'code',
      textField: 'name',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
  }

  selectFileCategory(item: any, type: any) {
    if(type === 'select'){
      this.selectedFileCategory.push(item);
    } else {
      this.selectedFileCategory = this.selectedFileCategory.filter(function(value) { return value.name != item.name;})
    }
    const index = this.fileCategories.map(function(e) { return e.name; }).indexOf(item.name);
    this.newForm.get("file_category_id").setValue(this.fileCategories[index].id);
  }
  
  selectMembership(item: any, type: any) {
    console.log("item",item);
    if(type === 'select'){
      this.selectedMembership.push(item);
      const index = this.memberships.map(function(e) { return e.title; }).indexOf(item.title);
      this.newForm.get("membership_id").setValue(this.memberships[index].id);
    } else {console.log("deselect");
      this.selectedMembership = this.selectedMembership.filter(function(value) { return value.title != item.title;})
      this.newForm.get("membership_id").setValue("0");
    }
    console.log("this.selectedMembership",this.selectedMembership);
  }
  
  selectType(item: any, type: any) {
    console.log("item",item);
    if(type === 'select'){
      this.selectedType.push(item);
    } else {
      this.selectedType = this.selectedType.filter(function(value) { return value.name != item.name;})
    }
    
    if(item.code == 'file') {
      let el: HTMLElement = this.file.nativeElement;
      el.click();
    }

    console.log("this.selectedType",this.selectedType);
    const index = this.types.map(function(e) { return e.name; }).indexOf(item.name);
    this.newForm.get("type").setValue(this.types[index].code);
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      author:  new FormControl('', []),
      description: new FormControl('', [Validators.required]),
      file_category_id:  new FormControl('', [Validators.required]),
      membership_id: new FormControl('', []),
      title: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      value: new FormControl('', [Validators.required,]),
      
      selectedFileCategory: new FormControl('', []),
      selectedType: new FormControl('', []),
      selectedMembership: new FormControl('', []),
    });
  }

  private setNewForm() {

    if(this.resource.file_category_id){
      const index = this.fileCategories.map(function(e) { return e.id; }).indexOf(this.resource.file_category_id);
      this.selectedFileCategory.push({id:this.fileCategories[index].id, name:this.fileCategories[index].name});
      this.newForm.get("selectedFileCategory").setValue(this.selectedFileCategory);
    }

    if(this.resource.membership_id){
      const index = this.memberships.map(function(e) { return e.id; }).indexOf(this.resource.membership_id);
      this.selectedMembership.push({id:this.memberships[index].id,title:this.memberships[index].title});
      this.newForm.get("selectedMembership").setValue(this.selectedMembership);
    }

    if(this.resource.type){
      console.log("this.resource.type", this.resource.type);
      const index = this.types.map(function(e) { return e.code; }).indexOf(this.resource.type);
      console.log("index", index);
      console.log("this.types[index]", this.types[index]);
      this.selectedType.push(this.types[index]);
      this.newForm.get("selectedType").setValue(this.selectedType);
    }
    
    this.newForm.get("author").setValue(this.resource.author);
    this.newForm.get("description").setValue(this.resource.description);
    this.newForm.get("file_category_id").setValue(this.resource.file_category_id);
    this.newForm.get("membership_id").setValue(this.resource.membership_id);
    this.newForm.get("title").setValue(this.resource.title);
    this.newForm.get("type").setValue(this.resource.type);
    this.newForm.get("value").setValue(this.resource.value);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  readPathFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.newForm.get("value").setValue(file.name);
      console.log("file", file.name);
      this.fileToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('author', this.f.author.value);
    recordToSave.append('description', this.f.description.value);
    recordToSave.append('file_category_id', this.f.file_category_id.value);
    recordToSave.append('membership_id', this.f.membership_id.value);
    recordToSave.append('title', this.f.title.value);
    recordToSave.append('type', this.f.type.value);
    recordToSave.append('value', this.f.value.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);
    if(this.fileToUpload) recordToSave.append('file', this.fileToUpload, this.fileToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    if (this.id === null && this.imageToUpload === null) {
      this.notifier.notify('warning','Veulliez sélectionner une image');
      return;
    }
    const resource = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, resource).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["resources"]);
      })
    } else {
      this.api.post(this.model + '/create', resource).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        this.router.navigate(["files/detail/" + res.id]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

  setCheckboxState(prop) {
    const newValue = (this.newForm.get(prop).value) ? 0 : 1;
    this.newForm.get(prop).setValue(newValue)
  }

}