import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api/api.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-individual-list',
  templateUrl: './individual-list.component.html',
  styleUrls: ['./individual-list.component.css']
})
export class IndividualListComponent implements OnInit {

  environment = environment;
  records: any;
  membership: any;
  url = "allIndividuals";
  currentPage = 1;
  filterAbo = "";
  termToSearch = "";

  constructor(private router: Router,
    private notifier: NotifierService,
    private api: ApiService) { }

  ngOnInit(): void {
    this.getAll();
    this.getMembership();
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  getAll(page = 0) {
    this.termToSearch = "";
    const url = 'users/' + this.url + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe(res => {
      this.records = res;
      console.log('this.records', this.records);
    });
  }

  edit(id) {
    this.router.navigate(['individuals/detail/' + id]);
  }

  delete(id) {
    this.api.delete("users/individual/destroy/" + id).subscribe(res => {
      console.log('res', res);
      this.notifier.notify('success',"La ressource a bien été supprimée");
      this.getAll();
    });
  }

  filterIndividuals(filter){
    if(filter != null){
      this.filterAbo = filter;
      if(this.termToSearch.length) this.search(this.termToSearch);
      else {
        this.api.post('users/filterIndividuals', { 'title' : 'membership' + this.filterAbo }).subscribe(data=>{
          this.records = data;
          console.log('filter records', this.records);
        })
      }
    }
    else if(filter == null){
      this.getAll();
    }
    
  }

  getMembership(){
    this.api.get('memberships').subscribe(data=>{
      this.membership = data;
      console.log(data);
    })
  }

  search(term){
    this.termToSearch = term;
    this.api.post("users/search", {term:term, 'title' : 'membership' + this.filterAbo }).subscribe(data => {
      this.records = data;
    })
  }
}
