<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Nom *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="name" placeholder="Titre" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Label utilisateur *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="user_equivalent" placeholder="Label utilisateur" [ngClass]="{ 'is-invalid': submitted && f.user_equivalent.errors }">
                                    <div *ngIf="submitted && f.user_equivalent.errors" class="invalid-feedback">
                                        <div *ngIf="f.user_equivalent.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Catégories *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez une catégorie'"
                                    [data]="problematicCategories"
                                    [(ngModel)]="selectedProblematicCategory"
                                    formControlName="selectedProblematicCategory"
                                    [settings]="singledropdownSettings"
                                    (onSelect)="selectFileCategory($event, 'select')"
                                    (onDeSelect)="selectFileCategory($event, 'deselect')"
                                    [ngClass]="{ 'is-invalid': submitted && f.problematic_category_id.errors }">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && f.problematic_category_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.problematic_category_id.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Tag</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <div class="ng-autocomplete w-100">
                                        <ng-autocomplete #auto
                                        [data]="tags"
                                        [searchKeyword]="keyword"
                                        (selected)='selectTag($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">                                 
                                        </ng-autocomplete>
                                        
                                        <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.name"></a>
                                        </ng-template>
                                        
                                        <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <div class="mt-3">
                                        <h4 *ngFor="let tag of filteredTags" class="d-inline-block mr-2 mb-2"><span class="badge badge-pill badge-secondary">{{ tag?.name }} <i class="fas fa-times ml-2" (click)="removeTag(tag)"></i></span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
        </div>
    </div>
</div>