<form class="b-form" [formGroup]="newForm">
  <!-- Row -->
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header bg-muted">
          <h4 class="m-b-0 text-white">Général</h4>
        </div>

        <hr class="m-t-0">
        <div class="card-body">
          <div class="row">
              <div class="col-4">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            <div class="col-sm-8 col-lg-8 col-md-8">
              <div class="form-group row">
                <div class="col-sm-3">
                  <div class="b-label">
                    <label for="inputEmail3" class="control-label col-form-label">Nom Prénom *</label>
                  </div>
                </div>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="inputEmail3" formControlName="name" placeholder="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Le champs est requis</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <angular-editor id="editor1" [formControl]="newForm.controls['content']" [config]="editorConfig">
                </angular-editor>
              </div>
              <div class="form-group row">
                <div class="b-label col-sm">
                  <label for="inputEmail3" class="control-label col-form-label">Catégorie *</label>
                </div>
                <div class="col-sm-8">
                  <select class="form-control" id="exampleFormControlSelect1" formControlName="testimony_category_id">
                    <option *ngFor="let category of testimoniesCategory" value="{{category.id}}"> {{category.name}}
                    </option>

                  </select>
                  <div *ngIf="submitted && f.testimony_category_id.errors" class="invalid-feedback">
                    <div *ngIf="f.testimony_category_id.errors.required">Le champs est requis</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Réponse</h4>
                </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
  <div class="row">
    <div class="col text-right">
      <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i>
        Annuler</a>
      <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i>
        Valider</button>
      <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i>
        Supprimer</button>
    </div>
  </div>
</div>
