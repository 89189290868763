import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TrainingListComponent } from './pages/trainings/training-list/training-list.component';
import { TrainingDetailComponent } from './pages/trainings/training-detail/training-detail.component';
import { SlideListComponent } from './pages/slides/slide-list/slide-list.component';
import { SlideDetailComponent } from './pages/slides/slide-detail/slide-detail.component';
import { ConferenceListComponent } from './pages/conferences/conference-list/conference-list.component';
import { ConferenceDetailComponent } from './pages/conferences/conference-detail/conference-detail.component';
import { QuoteListComponent } from './pages/quotes/quote-list/quote-list.component';
import { QuoteDetailComponent } from './pages/quotes/quote-detail/quote-detail.component';
import { ResourceListComponent } from './pages/resources/resource-list/resource-list.component';
import { ResourceDetailComponent } from './pages/resources/resource-detail/resource-detail.component';
import { ResourceCategoryListComponent } from './pages/resources/resource-category-list/resource-category-list.component';
import { ResourceCategoryDetailComponent } from './pages/resources/resource-category-detail/resource-category-detail.component';
import { CategoryListComponent } from './pages/categories/category-list/category-list.component';
import { CategoryDetailComponent } from './pages/categories/category-detail/category-detail.component';
import { TagListComponent } from './pages/tags/tag-list/tag-list.component';
import { TagDetailComponent } from './pages/tags/tag-detail/tag-detail.component';
import { TheoricalApproachListComponent } from './pages/theorical-approaches/theorical-approach-list/theorical-approach-list.component';
import { TheoricalApproachDetailComponent } from './pages/theorical-approaches/theorical-approach-detail/theorical-approach-detail.component';
import { SpecialityListComponent } from './pages/specialities/speciality-list/speciality-list.component';
import { SpecialityDetailComponent } from './pages/specialities/speciality-detail/speciality-detail.component';
import { ProblematicListComponent } from './pages/problematics/problematic-list/problematic-list.component';
import { ProblematicDetailComponent } from './pages/problematics/problematic-detail/problematic-detail.component';
import { ProblematicCategoryListComponent } from './pages/problematics/problematic-category-list/problematic-category-list.component';
import { ProblematicCategoryDetailComponent } from './pages/problematics/problematic-category-detail/problematic-category-detail.component';
import { MembershipListComponent } from './pages/memberships/membership-list/membership-list.component';
import { MembershipDetailComponent } from './pages/memberships/membership-detail/membership-detail.component';
import { SpeakerListComponent } from './pages/users/speakers/speaker-list/speaker-list.component';
import { SpeakerDetailComponent } from './pages/users/speakers/speaker-detail/speaker-detail.component';
import { AdministratorListComponent } from './pages/users/administrators/administrator-list/administrator-list.component';
import { AdministratorDetailComponent } from './pages/users/administrators/administrator-detail/administrator-detail.component';
import { FaqListComponent } from './pages/faqs/faq-list/faq-list.component';
import { FaqDetailComponent } from './pages/faqs/faq-detail/faq-detail.component';
import { FaqCategoryListComponent } from './pages/faqs/faq-category-list/faq-category-list.component';
import { FaqCategoryDetailComponent } from './pages/faqs/faq-category-detail/faq-category-detail.component';
import { PagesListComponent } from './pages/cms-pages/pages-list/pages-list.component';
import { PagesDetailComponent } from './pages/cms-pages/pages-detail/pages-detail.component';
import { IndividualListComponent } from './pages/users/individuals/individual-list/individual-list.component';
import { IndividualDetailComponent } from './pages/users/individuals/individual-detail/individual-detail.component';

import { CounselorListComponent } from './pages/users/counselors/counselor-list/counselor-list.component';
import { CounselorDetailComponent } from './pages/users/counselors/counselor-detail/counselor-detail.component';

import { PartnersListComponent } from './pages/partners/partners-list/partners-list.component';
import { PartnersDetailComponent } from './pages/partners/partners-detail/partners-detail.component';

import { CountryListComponent } from './pages/countries/country-list/country-list.component';
import { CountryDetailComponent } from './pages/countries/country-detail/country-detail.component';

import { LanguageDetailComponent } from './pages/languages/language-detail/language-detail.component';
import { LanguageListComponent } from './pages/languages/language-list/language-list.component';

import { EmailContentListComponent } from './pages/emailContents/email-content-list/email-content-list.component';
import { EmailContentDetailComponent } from './pages/emailContents/email-content-detail/email-content-detail.component';

import { ContentListComponent } from './pages/contents/content-list/content-list.component';
import { ContentDetailComponent } from './pages/contents/content-detail/content-detail.component';

import { EmailContactListComponent } from './pages/emailContacts/email-contact-list/email-contact-list.component';
import { EmailContactDetailComponent } from './pages/emailContacts/email-contact-detail/email-contact-detail.component';
import { AppointmentListComponent } from './pages/appointments/appointment-list/appointment-list.component';

import { TestimonyListComponent } from './pages/testimonies/testimony-list/testimony-list.component';
import { TestimonyDetailComponent } from './pages/testimonies/testimony-detail/testimony-detail.component';

import { TestimonyCategoryListComponent } from './pages/testimonies/testimony-category-list/testimony-category-list.component';
import { TestimonyCategoryDetailComponent } from './pages/testimonies/testimony-category-detail/testimony-category-detail.component';
import { FeedbackListComponent } from './pages/feedbacks/feedback-list/feedback-list.component';
import { ReportedAppointmentListComponent } from './pages/reportedAppointments/reported-appointment-list/reported-appointment-list.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'trainings',
        children: [
          { path: '', redirectTo: '/trainings/list', pathMatch: 'full' },
          {
            path: 'list',
            component: TrainingListComponent,
            data: {
              title: 'Formations',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Formations' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: TrainingDetailComponent,
            data: {
              title: 'Formations',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Formations', url: '/trainings' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: TrainingDetailComponent,
            data: {
              title: 'Formations',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Formations', url: '/trainings' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'conferences',
        children: [
          { path: '', redirectTo: '/conferences/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ConferenceListComponent,
            data: {
              title: 'Conférences',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Conférences' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: ConferenceDetailComponent,
            data: {
              title: 'Conférences',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Conférences', url: '/conferences' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: ConferenceDetailComponent,
            data: {
              title: 'Conférences',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Conférences', url: '/conferences' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'slides',
        children: [
          { path: '', redirectTo: '/slides/list', pathMatch: 'full' },
          {
            path: 'list',
            component: SlideListComponent,
            data: {
              title: 'Slides',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Slides' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: SlideDetailComponent,
            data: {
              title: 'Slides',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Slides', url: '/slides' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: SlideDetailComponent,
            data: {
              title: 'Slides',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Slides', url: '/slides' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'feedbacks',
        children: [
          { path: '', redirectTo: '/feedbacks/list', pathMatch: 'full' },
          {
            path: 'list',
            component: FeedbackListComponent,
            data: {
              title: 'Avis',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Avis' }
              ]
            }
          },
        ]
      },
      {
        path: 'faqs',
        children: [
          { path: '', redirectTo: '/faqs/list', pathMatch: 'full' },
          {
            path: 'list',
            component: FaqListComponent,
            data: {
              title: 'FAQ',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'FAQ' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: FaqDetailComponent,
            data: {
              title: 'FAQ',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'FAQ', url: '/faqs' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: FaqDetailComponent,
            data: {
              title: 'FAQ',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'FAQ', url: '/faqs' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'faqCategories',
        children: [
          { path: '', redirectTo: '/faqCategories/list', pathMatch: 'full' },
          {
            path: 'list',
            component: FaqCategoryListComponent,
            data: {
              title: 'Catégories FAQ',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories FAQ' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: FaqCategoryDetailComponent,
            data: {
              title: 'Catégories FAQ',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories FAQ', url: '/faqCategories' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: FaqCategoryDetailComponent,
            data: {
              title: 'Catégories FAQ',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories FAQ', url: '/faqCategories' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'quotes',
        children: [
          { path: '', redirectTo: '/quotes/list', pathMatch: 'full' },
          {
            path: 'list',
            component: QuoteListComponent,
            data: {
              title: 'Citations',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Citations' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: QuoteDetailComponent,
            data: {
              title: 'Citations',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Citations', url: '/quotes' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: QuoteDetailComponent,
            data: {
              title: 'Citations',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Citations', url: '/quotes' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'files',
        children: [
          { path: '', redirectTo: '/files/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ResourceListComponent,
            data: {
              title: 'Ressources',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Ressources' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: ResourceDetailComponent,
            data: {
              title: 'Ressources',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Ressources', url: '/files' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: ResourceDetailComponent,
            data: {
              title: 'Ressources',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Ressources', url: '/files' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'fileCategories',
        children: [
          { path: '', redirectTo: '/fileCategories/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ResourceCategoryListComponent,
            data: {
              title: 'Catégories Ressource',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories Ressource' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: ResourceCategoryDetailComponent,
            data: {
              title: 'Catégories Ressource',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories Ressource', url: '/fileCategories' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: ResourceCategoryDetailComponent,
            data: {
              title: 'Catégories Ressource',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories Ressource', url: '/fileCategories' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'categories',
        children: [
          { path: '', redirectTo: '/categories/list', pathMatch: 'full' },
          {
            path: 'list',
            component: CategoryListComponent,
            data: {
              title: 'Catégories',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: CategoryDetailComponent,
            data: {
              title: 'Catégories',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories', url: '/categories' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: CategoryDetailComponent,
            data: {
              title: 'Catégories',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories', url: '/categories' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'tags',
        children: [
          { path: '', redirectTo: '/tags/list', pathMatch: 'full' },
          {
            path: 'list',
            component: TagListComponent,
            data: {
              title: 'Tags',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Tags' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: TagDetailComponent,
            data: {
              title: 'Tags',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Tags', url: '/tags' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: TagDetailComponent,
            data: {
              title: 'Tags',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Tags', url: '/tags' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'theoricalApproaches',
        children: [
          { path: '', redirectTo: '/theoricalApproaches/list', pathMatch: 'full' },
          {
            path: 'list',
            component: TheoricalApproachListComponent,
            data: {
              title: 'Approches Théorique',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Approches Théorique' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: TheoricalApproachDetailComponent,
            data: {
              title: 'Approches Théorique',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Approches Théorique', url: '/theoricalApproaches' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: TheoricalApproachDetailComponent,
            data: {
              title: 'Approches Théorique',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Approches Théorique', url: '/theoricalApproaches' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'specialities',
        children: [
          { path: '', redirectTo: '/specialities/list', pathMatch: 'full' },
          {
            path: 'list',
            component: SpecialityListComponent,
            data: {
              title: 'Spécialités',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Spécialités' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: SpecialityDetailComponent,
            data: {
              title: 'Spécialités',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Spécialités', url: '/specialities' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: SpecialityDetailComponent,
            data: {
              title: 'Spécialités',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Spécialités', url: '/specialities' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'problematics',
        children: [
          { path: '', redirectTo: '/problematics/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ProblematicListComponent,
            data: {
              title: 'Problématiques',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Problématiques' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: ProblematicDetailComponent,
            data: {
              title: 'Problématiques',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Problématiques', url: '/problematics' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: ProblematicDetailComponent,
            data: {
              title: 'Problématiques',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Problématiques', url: '/problematics' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'problematicCategories',
        children: [
          { path: '', redirectTo: '/problematicCategories/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ProblematicCategoryListComponent,
            data: {
              title: 'Catégories Problématique',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories Problématique' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: ProblematicCategoryDetailComponent,
            data: {
              title: 'Catégories Problématique',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories Problématique', url: '/problematicCategories' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: ProblematicCategoryDetailComponent,
            data: {
              title: 'Catégories Problématique',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories Problématique', url: '/problematicCategories' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'individuals',
        children: [
          { path: '', redirectTo: '/individuals/list', pathMatch: 'full' },
          {
            path: 'list',
            component: IndividualListComponent,
            data: {
              title: 'Particuliers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Particuliers' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: IndividualDetailComponent,
            data: {
              title: 'Particuliers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Particuliers', url: '/individuals' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: MembershipDetailComponent,
            data: {
              title: 'Abonnements',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Abonnements', url: '/individuals' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'memberships',
        children: [
          { path: '', redirectTo: '/memberships/list', pathMatch: 'full' },
          {
            path: 'list',
            component: MembershipListComponent,
            data: {
              title: 'Abonnements',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Abonnements' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: MembershipDetailComponent,
            data: {
              title: 'Abonnements',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Abonnements', url: '/memberships' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: MembershipDetailComponent,
            data: {
              title: 'Abonnements',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Abonnements', url: '/memberships' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'speakers',
        children: [
          { path: '', redirectTo: '/speakers/list', pathMatch: 'full' },
          {
            path: 'list',
            component: SpeakerListComponent,
            data: {
              title: 'Conférenciers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Conférenciers' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: SpeakerDetailComponent,
            data: {
              title: 'Conférenciers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Conférenciers', url: '/speakers' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: SpeakerDetailComponent,
            data: {
              title: 'Conférenciers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Conférenciers', url: '/speakers' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'administrators',
        children: [
          { path: '', redirectTo: '/administrators/list', pathMatch: 'full' },
          {
            path: 'list',
            component: AdministratorListComponent,
            data: {
              title: 'Administrateurs',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Administrateurs' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: AdministratorDetailComponent,
            data: {
              title: 'Administrateurs',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Administrateurs', url: '/administrators' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: AdministratorDetailComponent,
            data: {
              title: 'Administrateurs',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Administrateurs', url: '/administrators' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'pages',
        children: [
          { path: '', redirectTo: '/pages/list', pathMatch: 'full' },
          {
            path: 'list',
            component: PagesListComponent,
            data: {
              title: 'Liste de page',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'List de page' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: PagesDetailComponent,
            data: {
              title: 'List de page',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'List de page', url: '/pages' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: PagesDetailComponent,
            data: {
              title: 'List de page',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'List de page', url: '/pages' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'counsellors',
        children: [
          { path: '', redirectTo: '/counsellors/list', pathMatch: 'full' },
          {
            path: 'list',
            component: CounselorListComponent,
            data: {
              title: 'Liste des conseillers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des conseillers' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: CounselorDetailComponent,
            data: {
              title: 'Liste des conseillers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des conseillers', url: '/counsellors' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: CounselorDetailComponent,
            data: {
              title: 'Liste des conseillers',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des conseillers', url: '/counsellors' },
                { title: "Création d'un témoignage" }
              ]
            }
          }
        ]
      },
      {
        path: 'partners',
        children: [
          { path: '', redirectTo: '/partners/list', pathMatch: 'full' },
          {
            path: 'list',
            component: PartnersListComponent,
            data: {
              title: 'Liste des partenaires',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des partenaires' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: PartnersDetailComponent,
            data: {
              title: 'Liste des partenaires',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des partenaires', url: '/partners' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: PartnersDetailComponent,
            data: {
              title: 'Liste des partenaires',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des partenaires', url: '/partners' },
                { title: "Création d'un partenaire" }
              ]
            }
          }
        ]
      },
      {
        path: 'countries',
        children: [
          { path: '', redirectTo: '/countries/list', pathMatch: 'full' },
          {
            path: 'list',
            component: CountryListComponent,
            data: {
              title: 'Liste des pays',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des pays' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: CountryDetailComponent,
            data: {
              title: 'Liste des pays',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des pays', url: '/countries' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: CountryDetailComponent,
            data: {
              title: 'Liste des pays',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des pays', url: '/countries' },
                { title: "Ajouter un pays" }
              ]
            }
          }
        ]
      },
      {
        path: 'languages',
        children: [
          { path: '', redirectTo: '/languages/list', pathMatch: 'full' },
          {
            path: 'list',
            component: LanguageListComponent,
            data: {
              title: 'Liste des langues',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des langues' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: LanguageDetailComponent,
            data: {
              title: 'Liste des langues',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des langues', url: '/languages' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: LanguageDetailComponent,
            data: {
              title: 'Liste des langues',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Liste des langues', url: '/languages' },
                { title: "Ajouter une langue" }
              ]
            }
          }
        ]
      },
      {
        path: 'contents',
        children: [
          { path: '', redirectTo: '/contents/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ContentListComponent,
            data: {
              title: "Liste de contenu",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste de contenu" }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: ContentDetailComponent,
            data: {
              title: "Liste de contenu",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste de contenu", url: '/contents' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: ContentDetailComponent,
            data: {
              title: "Liste de contenu",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste de contenu", url: '/contents' },
                { title: "Ajouter un contenu" }
              ]
            }
          }
        ]
      },
      {
        path: 'emailContacts',
        children: [
          { path: '', redirectTo: '/emailContacts/list', pathMatch: 'full' },
          {
            path: 'list',
            component: EmailContactListComponent,
            data: {
              title: "Liste des messages",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des messages" }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: EmailContactDetailComponent,
            data: {
              title: "Liste des messages",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des messages", url: '/emailContacts' },
                { title: 'Répondre au message' }
              ]
            }
          },
          {
            path: 'create',
            component: EmailContactDetailComponent,
            data: {
              title: "Liste des messages",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des messages", url: '/emailContacts' },
                { title: "Écrire un message" }
              ]
            }
          }
        ]
      },
      {
        path: 'emailContents',
        children: [
          { path: '', redirectTo: '/emailContents/list', pathMatch: 'full' },
          {
            path: 'list',
            component: EmailContentListComponent,
            data: {
              title: "Liste des modèles du contenu d'un email",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des modèles du contenu d'un email" }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: EmailContentDetailComponent,
            data: {
              title: "Liste des modèles du contenu d'un email",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des modèles du contenu d'un email", url: '/emailContents' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: EmailContentDetailComponent,
            data: {
              title: "Liste des modèles du contenu d'un email",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des modèles du contenu d'un email", url: '/emailContents' },
                { title: "Ajouter un modèle" }
              ]
            }
          }
        ]
      },
      {
        path: 'appointments',
        children: [
          { path: '', redirectTo: '/appointments/list', pathMatch: 'full' },
          {
            path: 'list',
            component: AppointmentListComponent,
            data: {
              title: "Liste des consultations",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des consultations" }
              ]
            }
          },
          // {
          //   path: 'detail/:id',
          //   component: AppointmentDetailComponent,
          //   data: {
          //     title: "Liste des consultations",
          //     urls: [
          //       { title: 'Dashboard', url: '/dashboard' },
          //       { title: "Liste des consultations", url: '/appointments' },
          //       { title: 'Détail' }
          //     ]
          //   }
          // },
        ]
      },
      {
        path: 'testimonies',
        children: [
          { path: '', redirectTo: '/testimonies/list', pathMatch: 'full' },
          {
            path: 'list',
            component: TestimonyListComponent,
            data: {
              title: 'Témoignages',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Témoignages' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: TestimonyDetailComponent,
            data: {
              title: 'Témoignages',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Témoignages', url: '/testimonies' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: TestimonyDetailComponent,
            data: {
              title: 'Témoignages',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Témoignages', url: '/testimonies' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'testimoniesCategory',
        children: [
          { path: '', redirectTo: '/testimoniesCategory/list', pathMatch: 'full' },
          {
            path: 'list',
            component: TestimonyCategoryListComponent,
            data: {
              title: 'Catégories de Témoignage',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories de Témoignage' }
              ]
            }
          },
          {
            path: 'detail/:id',
            component: TestimonyCategoryDetailComponent,
            data: {
              title: 'Catégories de Témoignage',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories de Témoignage', url: '/testimoniesCategory' },
                { title: 'Détail' }
              ]
            }
          },
          {
            path: 'create',
            component: TestimonyCategoryDetailComponent,
            data: {
              title: 'Catégories de Témoignage',
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: 'Catégories de Témoignage', url: '/testimoniesCategory' },
                { title: 'Création' }
              ]
            }
          }
        ]
      },
      {
        path: 'reported-appointments',
        children: [
          { path: '', redirectTo: '/reported-appointments/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ReportedAppointmentListComponent,
            data: {
              title: "Liste des reports de consultations",
              urls: [
                { title: 'Dashboard', url: '/dashboard' },
                { title: "Liste des reports de consultations" }
              ]
            }
          },
        ]
      },

    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];
