import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './../api/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from './../../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private router: Router,
    private notifier: NotifierService
  ) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  id(): any {
    return localStorage.getItem('id');
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return localStorage.getItem('token') != null;
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAdmin(): boolean {
    return (localStorage.getItem('user_type_id') == '1') ? true : false;
  }

  login(data): void {
    console.log("login")
    this.http.post(API_URL+'login', data).subscribe((data: {id:null,lastname:null,firstname:null,email:null,image:null,user_type_id:string,token:null}) => {
        console.log("data login", data);
          localStorage.setItem('id', data.id);
          localStorage.setItem('token', data.token);
          localStorage.setItem('firstname', data.firstname);
          localStorage.setItem('lastname', data.lastname);
          localStorage.setItem('email', data.email);
          localStorage.setItem('image', data.image);
          localStorage.setItem('user_type_id', data.user_type_id);
          this.api.setHeaderOptions();
          this.router.navigate(['dashboard']);
        // }
      },
      err => {
        this.notifier.notify( "error", "Erreur sur vos codes d'accès" );
      }
    );
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): any {
    console.log('logout auth')
    this.clear();
    this.router.navigate(['/login']);
  }
}
