import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Training } from 'src/app/models/training/training.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Conference } from 'src/app/models/conference/conference.model';

@Component({
  selector: 'app-training-detail',
  templateUrl: './training-detail.component.html',
  styleUrls: ['./training-detail.component.css']
})
export class TrainingDetailComponent implements OnInit {

  @ViewChild('file') file: ElementRef<HTMLElement>;
  environment = environment;
  id: any = null;
  model = "trainings";

  record: Training;

  selectedFilePath: any;
  imageToUpload: any = null;
  newForm: FormGroup;
  memberships: any;
  categories: any;
  problematics: any;
  conferencesLearnybox: any;
  authors: any[] = [];
  urgencies: any[] = ['Faible','Intermédiaire','Élevé'];

  submitted = false;

  dropdownSettings = {};
  singledropdownSettings = {};
  singledropdownMembershipsSettings = {};
  singledropdownLearnyboxIdSettings = {};
  
  selectedCategory = [];
  selectedMembership = [];
  selectedUrgency = [];

  problematicsRef: any = [];
  selectedProblematics = [];
  selectedLearnyboxId = [];

  authorsRef: any = [];
  selectedAuthors = [];

  selectedTextWrapper = 1;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '600px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
      console.log('id', this.id);
    });
  }

  ngOnInit() {
    this.getMemberships();
    this.setDropdownList();
    // this.getAllConferencesFromLearnybox();
  }

  
  getAllConferencesFromLearnybox() {
    this.api.get("trainingLearnybox").subscribe((data: any) => {
      this.conferencesLearnybox = data;
      console.log("getAllConferencesFromLearnybox", this.conferencesLearnybox);
    },(err)=>{
      console.log("err",err);
    })
  }

  setLearnyboxId (val) {
    return Array.from(val);
  }

  getRecord() {
    if (this.id != null) {
      console.log("this.id", this.id);
      this.api.get(this.model + "/" + this.id).subscribe((res: Training) => {
        this.record = res;
        console.log("this.record", res);
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.record.image;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+image';
    }
  }

  getMemberships() {
    this.api.get('list/memberships').subscribe((data) => {
      this.memberships = data;
      this.getProblematics();
    })
  }

  getProblematics() {
    this.api.get('list/problematics').subscribe((data) => {
      this.problematics = data;
      this.getCategories();
    })
  }

  getCategories() {
    this.api.get('list/categories').subscribe((data) => {
      this.categories = data;
      this.getAllAuthors();
    })
  }

  getAllAuthors() {
    this.api.get('users/allAuthors').subscribe((data: any[]) => {
      this.authors = data;
      this.getRecord();
    })
  }
  
  setDropdownList() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      itemsShowLimit: 120,
      allowSearchFilter: true
    };
    this.singledropdownSettings = {
      singleSelection: true,
      textField: 'name',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
    this.singledropdownMembershipsSettings = {
      singleSelection: true,
      textField: 'title',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
    this.singledropdownLearnyboxIdSettings = {
      singleSelection: true,
      idField: 'id_training',
      textField: 'nom_formation',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
  }

  
  selectLearnyboxId(item: any, type: any) {
    if(type === 'select'){
      this.selectedLearnyboxId.push(item);
    } else {
      this.selectedLearnyboxId = this.selectedLearnyboxId.filter(function(value) { return value.nom != item.nom;})
    }
    const category = this.conferencesLearnybox.map(function(e) { return e.nom_formation; }).indexOf(item.nom_formation);
    const id = (category) ? this.conferencesLearnybox[category].id_training : 0;
    console.log('id',id);
    this.newForm.get("learnybox_id").setValue(this.conferencesLearnybox[category].id_training);
  }

  selectCategory(item: any, type: any) {
    if(type === 'select'){
      this.selectedCategory.push(item);
    } else {
      this.selectedCategory = this.selectedCategory.filter(function(value) { return value.name != item.name;})
    }
    const category = this.categories.map(function(e) { return e.name; }).indexOf(item.name);
    this.newForm.get("category_id").setValue(this.categories[category].id);
  }
  
  selectMembership(item: any, type: any) {
    let id = 0;
    if(type === 'select'){
      this.selectedMembership.push(item);
      const membership = this.memberships.map(function(e) { return e.title; }).indexOf(item.title);
      id = this.memberships[membership].id;
    } else {
      this.selectedMembership = this.selectedMembership.filter(function(value) { return value.title != item.title;})
    }
    
    this.newForm.get("membership_id").setValue(id);
  }
  
  selectUrgency(item: any, type: any) {
    if(type === 'select'){
      this.selectedUrgency.push(item);
    } else {
      this.selectedUrgency = this.selectedUrgency.filter(function(value) { return value.name != item.name;})
    }
    
    this.newForm.get("urgency").setValue(this.urgencies.indexOf(item)+1);
  }
  
  selectProblematic(item: any, type: any) {
    if(type === 'select'){
      this.selectedProblematics.push(item);
      this.problematicsRef.push(item.id);
    } else {
      this.problematicsRef = this.problematicsRef.filter(function(value) { return value != item.id;})
      this.selectedProblematics = this.selectedProblematics.filter(function(value) { return value.name != item.name;})
    }
    
    this.newForm.get("problematics").setValue(this.problematicsRef.join(','));
  }

  selectAllProblematics(items: any) {
    this.selectedProblematics = items;
    this.problematicsRef = [];

    items.forEach((item)=>{
      this.problematicsRef.push(item.id);
    })
    this.newForm.get("problematics").setValue(this.problematicsRef.join(','))
  }
  
  selectAuthor(item: any, type: any) {
    if(type === 'select'){
      this.selectedAuthors.push(item);
      this.authorsRef.push(item.id);
    } else {
      this.authorsRef = this.authorsRef.filter(function(value) { return value != item.id;})
      this.selectedAuthors = this.selectedAuthors.filter(function(value) { return value.name != item.name;})
    }
    
    this.newForm.get("authors").setValue(this.authorsRef.join(','));
  }

  selectAllAuthors(items: any, type: any) {
    this.selectedAuthors = items;
    this.authorsRef = [];

    if(type === 'select')
      items.forEach((item)=>{
        this.authorsRef.push(item.id);
      })
    this.newForm.get("authors").setValue(this.authorsRef.join(','))
  }



  private initNewForm() {

    this.newForm = new FormGroup({
      title: new FormControl('', [Validators.required,]),
      description: new FormControl('', [Validators.required]),
      subtitle:  new FormControl('', [Validators.required]),
      target:  new FormControl('', [Validators.required]),
      synopsis:  new FormControl('', [Validators.required]),
      skills:  new FormControl('', [Validators.required]),
      program:  new FormControl('', [Validators.required]),
      bullet_points:  new FormControl('', [Validators.required]),
      trailer:  new FormControl('', []),
      link:  new FormControl('', [Validators.required]),
      urgency:  new FormControl('', [Validators.required]),
      duration:  new FormControl('', [Validators.required]),
      bestsell:  new FormControl('', []),
      price:  new FormControl('', [Validators.required]),
      authors:  new FormControl('', [Validators.required]),
      category_id:  new FormControl('', []),
      membership_id: new FormControl('', []),
      learnybox_id: new FormControl('', []),
      novelty: new FormControl('', []),
      published: new FormControl('', []),
      problematics: new FormControl('', []),
      selectedProblematics: new FormControl('', []),
      selectedAuthors: new FormControl('', []),
      selectedCategory: new FormControl('', []),
      selectedMembership: new FormControl('', []),
      selectedLearnyboxId: new FormControl('', []),
      selectedUrgency: new FormControl('', []),
    });
  }

  private setNewForm() {
    if(this.record.problematics){
      this.record.problematics.forEach(element => {
        this.selectedProblematics.push({id:element.id,name:element.name});
        this.problematicsRef.push(element.id);
      });

      this.newForm.get("problematics").setValue(this.problematicsRef.join(','))
      this.newForm.get("selectedProblematics").setValue(this.selectedProblematics);
    }

    if(this.record.authors){
      this.record.authors.forEach(element => {
        this.selectedAuthors.push({id:element.id,name:element.lastname + " " + element.firstname});
        this.authorsRef.push(element.id);
      });
      this.newForm.get("authors").setValue(this.authorsRef.join(','));
      this.newForm.get("selectedAuthors").setValue(this.selectedAuthors);
    }

    if(this.record.category_id){
      const category = this.categories.map(function(e) { return e.id; }).indexOf(this.record.category_id);
      this.selectedCategory.push({id:this.categories[category].id, name:this.categories[category].name});
      this.newForm.get("selectedCategory").setValue(this.selectedCategory);
    }

    if(this.record.membership_id){
      const membership = this.memberships.map(function(e) { return e.id; }).indexOf(this.record.membership_id);
      this.selectedMembership.push({id:this.memberships[membership].id,title:this.memberships[membership].title});
      this.newForm.get("selectedMembership").setValue(this.selectedMembership);
    }

    // if(this.record.learnybox_id != null){
    //   const category = this.conferencesLearnybox.map(function(e) { return e.learnybox_id }).indexOf(this.record.learnybox_id);
    //   console.log("learnybox_id index", category);
    //   if(category != -1) {
    //     this.selectedLearnyboxId.push({id:this.conferencesLearnybox[category].id_training, nom:this.conferencesLearnybox[category].nom_formation});
    //     this.newForm.get("selectedLearnyboxId").setValue(this.selectedLearnyboxId);
    //   }
    // }

    if(this.record.urgency){
      this.selectedUrgency.push(this.urgencies[this.record.urgency-1]);
      this.newForm.get("selectedUrgency").setValue(this.selectedUrgency);
    }
    
    this.newForm.get("title").setValue(this.record.title);
    this.newForm.get("description").setValue(this.record.description);
    this.newForm.get("subtitle").setValue(this.record.subtitle);
    this.newForm.get("target").setValue(this.record.target);
    this.newForm.get("synopsis").setValue(this.record.synopsis);
    this.newForm.get("skills").setValue(this.record.skills);
    this.newForm.get("program").setValue(this.record.program);
    this.newForm.get("bullet_points").setValue(this.record.bullet_points);
    this.newForm.get("trailer").setValue(this.record.trailer);
    this.newForm.get("link").setValue(this.record.link);
    this.newForm.get("urgency").setValue(this.record.urgency);
    this.newForm.get("duration").setValue(this.record.duration);
    this.newForm.get("bestsell").setValue(this.record.bestsell);
    this.newForm.get("price").setValue(this.record.price);
    this.newForm.get("authors").setValue(this.record.authors);
    this.newForm.get("category_id").setValue(this.record.category_id);
    this.newForm.get("membership_id").setValue(this.record.membership_id);
    this.newForm.get("learnybox_id").setValue(this.record.learnybox_id);
    this.newForm.get("novelty").setValue(this.record.novelty);
    this.newForm.get("published").setValue(this.record.published);
    this.newForm.get("problematics").setValue(this.problematicsRef);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('title', this.f.title.value);
    recordToSave.append('description', this.f.description.value);
    
    recordToSave.append('subtitle', this.f.subtitle.value);
    
    recordToSave.append('target', this.f.target.value);
    recordToSave.append('synopsis', this.f.synopsis.value);
    
    recordToSave.append('skills', this.f.skills.value);
    recordToSave.append('program', this.f.program.value);
    recordToSave.append('bullet_points', this.f.bullet_points.value);
    recordToSave.append('trailer', this.f.trailer.value);
    recordToSave.append('link', this.f.link.value);
    recordToSave.append('urgency', this.f.urgency.value);
    recordToSave.append('duration', this.f.duration.value);
    recordToSave.append('bestsell', this.f.bestsell.value);
    recordToSave.append('price', this.f.price.value);
    recordToSave.append('authors', this.authorsRef.join(','));
    recordToSave.append('category_id', this.f.category_id.value);
    recordToSave.append('learnybox_id', this.f.learnybox_id.value);
    recordToSave.append('membership_id', this.f.membership_id.value);
    recordToSave.append('novelty', this.f.novelty.value);
    recordToSave.append('published', this.f.published.value);
    recordToSave.append('problematics', this.f.problematics.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veuillez verifier les champs obligatoires');
      return;
    }
    if (this.id === null && this.imageToUpload === null) {
      this.notifier.notify('warning','Veulliez sélectionner une image');
      return;
    }
    const training = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, training).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["trainings"]);
      })
    } else {
      this.api.post(this.model + '/create', training).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        this.router.navigate(["trainings/detail/" + res.id]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

  setCheckboxState(prop) {
    const newValue = (this.newForm.get(prop).value) ? 0 : 1;
    this.newForm.get(prop).setValue(newValue)
  }

}