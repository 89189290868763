<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Destinataire :</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <select class="form-control" id="exampleFormControlSelect1" formControlName="user_id">
                                        <option *ngFor="let membership of memberships" value="{{membership.id}}"> {{membership.lastname}} {{membership.firstname}} </option>
                                    </select>
                                    <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.user_id.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="record">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Message reçu :</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group" [innerHTML]="record?.message">
                                        <!-- <quill-editor style="height: 800px;" [formControl]="newForm.controls['content']"></quill-editor> -->
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!record">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Contenue *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <!-- <quill-editor style="height: 800px;" [formControl]="newForm.controls['content']"></quill-editor> -->
                                        <angular-editor id="editor1" formControlName="message" [config]="editorConfig"></angular-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="record">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Contenue *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <!-- <quill-editor style="height: 800px;" [formControl]="newForm.controls['content']"></quill-editor> -->
                                        <angular-editor id="editor1" formControlName="answer" [config]="editorConfig"></angular-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->  
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
            <a href="{{ environment.siteUrl }}{{ record?.slug }}" target="_blank" class="btn btn-rounded btn-primary float-left"><i class="icon-eye"></i> Aperçu</a>
        </div>
    </div>
</div>