import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit {

  environment = environment;
  records: any;
  url = "appointments";
  currentPage = 1;
  countStatus;
  counselors;
  statuses;
  options = {
    filter : false,
    counselor_id : 0,
    status_id : 0
  };
  newForm: FormGroup;
  checkform: FormGroup;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService) { }

    ngOnInit() {
      this.initNewForm();
      this.getAll();
      this.getCountStatus();
      this.getAllCounselor();
      this.getAllStatus();
    }
  

    private initNewForm() {

      this.newForm = new FormGroup({
        counselor_id: new FormControl('', []),
        status_id: new FormControl('', []),
      });
    }
  

    arrayOne(n: number): any[] {
      return Array(n);
    }

    getBgStatus(id) {
      switch(id){
        case 1: return "badge-secondary"; //Libre
        case 2: return "badge-warning"; //Réservée
        case 3: return "badge-primary"; //Terminée
        case 4: return "badge-info"; //Reportée
        case 5: return "badge-success"; //Rémunéréz,
        case 6: return "badge-danger"; //Annulée
      }
    }

    getCountStatus(){
      this.api.get('count_appointment_status').subscribe( data => {
        this.countStatus = data;
      })
    }

    getAllStatus(){
      this.api.get('status_appointment').subscribe( data => {
        this.statuses = data;
      })
    }

    getAll(page = 0) {
      const url = this.url + ((page) ? ("?page=" + page) : "");
      this.api.post(url, this.options).subscribe(res => {
        this.records = res;
        
        let offset = new Date().getTimezoneOffset();
        this.records.data.forEach((item, index) => {
          let explD = item.due_date.split(" ")[0].split("-");
          let explT = item.due_date.split(" ")[1].split(":");
          this.records.data[index].due_date = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
        })
        console.log('this.records', this.records);
        this.flushAllArray();
      });
    }

    getAllCounselor(){
      this.api.get('users/allCounselorsNoPag').subscribe( data => {
        this.counselors = data;
        console.log("consellors", this.counselors);
        
      })
    }
  
    edit(id) {
      this.router.navigate([this.url + '/detail/' + id]);
    }
  
    delete(id) {
      this.api.get('delete/appointment/' + id).subscribe(res => {
        console.log('res', res);
        this.notifier.notify('success',"l'élément a bien été supprimé");
        this.getAll();
      });
    }

    setAppointmentToPaid(id){
      this.api.get('appointment_status/paid/' + id).subscribe(data=>{
        this.getAll();
        this.getCountStatus();
      })
    }

    get f() { return this.newForm.controls; }

    reinitialiser(){
      this.options.filter = false;
      this.options.counselor_id = 0;
      this.options.status_id = 0;
      this.initNewForm();
      this.getAll();
    }

    onSubmit() {
      
      this.options.filter = true;
      this.options.counselor_id = this.f.counselor_id.value;
      this.options.status_id = this.f.status_id.value;
      this.getAll();

    }

    checkArray = [];
    showInfos = false;
    onCheckboxChange(e,who) {      
      if (e.target.checked) {
        this.checkArray.push(who);

      } else {
        const index = this.checkArray.indexOf(who);

        if (index > -1) {
          this.checkArray.splice(index, 1);
        }
          
      }
      this.showInfos = (this.checkArray.length != 0) ? true : false;
      console.log("checkArray", this.checkArray);
      console.log("showInfos", this.showInfos);
      (this.showInfos) ? this.findElementInRecords() : this.total = 0;
      
    }

    myIndex = [];
    total = 0;
    findElementInRecords(){
      this.myIndex = [];
      this.checkArray.forEach(element => {
        const toto = this.records.data.map(function(e) { return e.id; }).indexOf(element);
        this.myIndex.push(toto);
        console.log("this.myIndex", this.myIndex);
      });

      this.total = 0;
      for(var i = 0; i < this.records.data.length; i++){
        this.myIndex.forEach(element => {
          if(element == i) this.total = this.total + (this.records.data[i].pricing);
        });
      }

    }

    deleteMassAppointment(){
      const data = {
        ids : this.checkArray
      }
      this.api.post('delete_mass/appointment', data).subscribe( res => {
        this.getAll();
        this.getCountStatus();
        this.flushAllArray();
      })
    }

    setMassAppointmentToPaid(){
      var wrong = false;
      this.checkArray.forEach(element => {
        for(var i = 0; i < this.records.data.length; i++){
          if(element == this.records.data[i].id){
            if(this.records.data[i].status_appointment_id != 3){
              wrong = true;
            }
          }
        }
      });
      const data = {
        ids : this.checkArray
      }
      if(!wrong){
        this.api.post('mass_appointment_status/paid', data).subscribe( res => {
          this.getAll();
          this.getCountStatus();
          this.flushAllArray();
        })
      }else{
        this.notifier.notify('warning', "Opération impossible, car certaines consultations sélectionnées ne sont pas encore terminées.");
      }
    }
  

    flushAllArray(){
      this.checkArray = [];
      this.myIndex = [];
    }

    roundPrice(number) {
      return Math.ceil(number);
    }
}
