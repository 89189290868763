import { Component, OnInit } from '@angular/core';

import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist';

import { environment } from './../../../environments/environment';
import { ApiService } from '../../services/api/api.service';

declare var require: any;

interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  environnment = environment;

  /* Count Users */
  stats;
  month = [null,"Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"];

  data = {
    Line: {
      labels: [1, 2, 3, 4, 5, 6, 7, 8],
      series: [[0, 5, 6, 8, 25, 9, 8, 24], [0, 3, 1, 2, 8, 1, 5, 1]]
    }
  };

  lineChart: Chart = {
    type: 'Line',
    data: {
      labels: [1, 2, 3, 4, 5, 6, 7, 8],
      series: [[0, 5, 6, 8, 25, 9, 8, 24], [0, 3, 1, 2, 8, 1, 5, 1]]
    },
    options: {
      low: 0,
      high: 6000,
      showArea: true,
      fullWidth: true,
      axisY: {
        onlyInteger: true,
        scaleMinSpace: 40,
        offset: 20,
        labelInterpolationFnc: function(value: number): string {
          return value / 1000 + 'k';
        }
      }
    }
  };

  sum = [];
  globalCA = 0;
  countStatus: any;
  topCounselors: any;
  topTrainings: any;
  topConferences: any;

  totalIndividuals: any;
  totalCounselors: any;

  totalDecouverte: any;
  totalSerenite: any;

  constructor(
    private api : ApiService
  ) { }

  ngOnInit(): void {
    this.getCountStatus();
    this.getSalesByMonth();
    this.getSumByStatusAppointmentId(2);
    this.getSumByStatusAppointmentId(3);
    this.getSumByStatusAppointmentId(5);
    this.getGlobalCA();
    this.getTopCounselors();
    this.getTopConferences();
    this.getTopTrainings();
    this.getTotalIndividuals();
    this.getTotalCounselors();
    this.getTotalDecouverte();
    this.getTotalSerenite();
  }

  getTotalDecouverte() {
    this.api.get("getTotalUsersByMembershipId/1").subscribe(data=>{
      this.totalDecouverte = data;
    })
  }

  getTotalSerenite() {
    this.api.get("getTotalUsersByMembershipId/2").subscribe(data=>{
      this.totalSerenite = data;
    })
  }

  getTotalIndividuals() {
    this.api.get("getTotalUsersByUserTypeId/2").subscribe(data=>{
      this.totalIndividuals = data;
    })
  }

  getTotalCounselors() {
    this.api.get("getTotalUsersByUserTypeId/3").subscribe(data=>{
      this.totalCounselors = data;
    })
  }

  getTopCounselors() {
    this.api.get("getTopCounselors").subscribe(data=>{
      this.topCounselors = data;
    })
  }

  getTopConferences() {
    this.api.get("getTopConferences").subscribe(data=>{
      this.topConferences = data;
    })
  }

  getTopTrainings() {
    this.api.get("getTopTrainings").subscribe(data=>{
      this.topTrainings = data;
    })
  }
  
  getSalesByMonth() {    
    this.api.get('getSalesByMonth').subscribe((data: any)=>{
      console.log("getSalesByMonth", data);
      const serie = [];
      const labels = [];
      
      data.forEach((element: any) => {
        serie.push(parseInt(element.sum));
        labels.push(this.month[element.month]);
      });
      this.data['Line'].labels = labels;
      this.data['Line'].series = [serie];
      
      this.lineChart.data = this.data.Line;
    });
  }

  getCountStatus(){
    this.api.get('count_appointment_status').subscribe( data => {
      this.countStatus = data;
    })
  }

  getSumByStatusAppointmentId(statusAppointmentId) {
    
    this.api.get("getSumByStatusAppointmentId/" + statusAppointmentId).subscribe( (data: any) => {
      console.log('getSumByStatusAppointmentId', data);
      this.sum[statusAppointmentId] = Math.ceil(data*1.20);
    })
  }

  getGlobalCA() {
    this.api.get("getGlobalCA").subscribe((data: any) => {
      this.globalCA = parseInt(data);
    })
  }
  
  ceilNumber(amount) {
    return Math.ceil(amount);
  }
}
