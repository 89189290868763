<div class="row">
  <div class="col-lg-4 col-md-6">
      <div class="card border-bottom border-orange">
          <div class="card-body">
              <div class="d-flex no-block align-items-center">
                  <div>
                      <h2>{{countStatus?.count_reserved}} </h2>
                      <h6 class="text-orange">Consultations réservées</h6>
                  </div>
                  <div class="ml-auto">
                      <span class="text-orange display-6">
                          <i class="ti-clipboard"></i>
                      </span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="col-lg-4 col-md-6">
      <div class="card border-bottom border-purple">
          <div class="card-body">
              <div class="d-flex no-block align-items-center">
                  <div>
                      <h2>{{countStatus?.count_done}} </h2>
                      <h6 class="text-purple">Consultations terminées</h6>
                  </div>
                  <div class="ml-auto">
                      <span class="text-purple display-6">
                          <i class="ti-clipboard"></i>
                      </span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="col-lg-4 col-md-6">
      <div class="card border-bottom border-cyan">
          <div class="card-body">
              <div class="d-flex no-block align-items-center">
                  <div>
                      <h2>{{countStatus?.count_payed}}</h2>
                      <h6 class="text-cyan">Consultations Payées</h6>
                  </div>
                  <div class="ml-auto">
                      <span class="text-cyan display-6">
                          <i class="ti-clipboard"></i>
                      </span>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <!--- Complete Table -->
    <div class="card">
      <div class="card-body">
        <form class="row" [formGroup]="newForm">
          <div class="col-lg-4 col-md-6 form-group">
            <label for="exampleSelect1">Conseillés</label>
            <select formControlName="counselor_id" class="form-control" id="exampleSelect1">
              <option >-- Choissisez un conseillé --</option>
              <option *ngFor="let counselor of counselors" value="{{counselor.id}}">{{counselor?.lastname | uppercase}} {{counselor?.firstname | titlecase}} </option>

            </select>
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="exampleSelect2">Statuts</label>
            <select formControlName="status_id" class="form-control" id="exampleSelect2">
              <option>-- Choissisez un statut --</option>
              <option *ngFor="let status of statuses" value="{{status.id}}">{{status?.name}} </option>

            </select>
          </div>
          <div class="col-lg-4 col-md-6 form-group mt-4">
            <button class="btn btn-secondary btn-rounded mx-3" (click)="reinitialiser()">Réinitialiser</button>
            <button class="btn btn-primary btn-rounded mx-3" (click)="onSubmit()">Filtrer</button>
          </div>
        </form>
        <div class="row mt-2" *ngIf="showInfos">
          <div class="col-lg-4 col-md-6 form-group">
            <button class="btn btn-danger btn-rounded mx-3" (click)="deleteMassAppointment()">Supprimer les séléctionnés</button>
          </div>
          <div class="col-lg-8 col-md-6 form-group">

              Total à rémunérer : {{total}}
              <button class="btn btn-secondary btn-rounded mx-3" (click)="setMassAppointmentToPaid()">Rémunérer</button>
            
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped border mt-4" style="min-width: 1000px;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Conseillés</th>
                <th scope="col">Particuliers</th>
                <th scope="col">Date</th>
                <th scope="col">Statut</th>
                <th scope="col">Prix</th>
                <th scope="col" class="text-right col-actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of records?.data">
                <td>
                  <input id="c{{record.id}}" type="checkbox" class="d-inline-block ml-0"  [value]="record.id" (change)="onCheckboxChange($event, record.id)">
                  <span class="d-inline-block ml-2">{{ record?.id }}</span>
                </td>
                <td class="img-radius align-top">
                  <img class="mr-2 float-left" src="{{ environment.uploadPath }}users/{{ record?.counselor?.image }}" class="mr-2 rounded-circle float-left" width="40px" height="40px">
                  <div class="float-left">
                    <h5 class="m-b-0 font-16 font-medium">{{ record?.counselor?.firstname }}<br/>{{ record?.counselor?.lastname }}</h5>
                  </div>
                </td>
                <td class="img-radius align-top">
                  <img *ngIf="record?.individual" class="mr-2 float-left" src="{{ environment.uploadPath }}users/{{ record?.individual?.image }}" class="mr-2 rounded-circle float-left" width="40px" height="40px">
                  <div *ngIf="record?.individual" class="float-left">
                    <h5 class="m-b-0 font-16 font-medium">{{ record?.individual?.firstname }}<br/>{{ record?.individual?.lastname }}</h5>
                  </div>
                </td>
                <td>
                  <span><i class="icon-calender"></i> {{ record?.due_date | date:'shortDate' }}</span><br />
                  <span><i class="icon-clock"></i> {{ record?.due_date | date:'shortTime' }}</span>
                </td>
                <td>
                  <span class="badge mr-2" [ngClass]="getBgStatus(record?.status_appointment?.id)" style="width: 65px;">{{ record?.status_appointment?.name }}</span> 
                </td>
                <td>
                  <span class="">Conseiller : {{ record?.pricing }}€</span><br/>
                  <span class="">Familipsy : {{ roundPrice(record?.pricing * 0.2) }}€</span><br/>
                  <span class="">Taxe : {{ roundPrice((record?.pricing * 1.2) * 0.13) }}€</span><br/>
                </td>
                <td align="right">
                  <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="edit(record?.id)"><i
                      class="icon-pencil"></i></button>
                  <button *ngIf="record.status_appointment_id == 3" type="button" class="btn btn-circle btn-outline-secondary mr-2 mb-2" (click)="setAppointmentToPaid(record?.id)" ><i
                      class="fas fa-dollar-sign"></i></button>
                  <button type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2"
                    (click)="delete(record?.id)"><i class="icon-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true"
            [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true"
            (pageChange)="getAll($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
