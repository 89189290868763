<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Nom *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="lastname" placeholder="Nom du conférencier" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                                    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Prénom *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="firstname" placeholder="Prénom du conférencier" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                                    <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Genre *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez un genre'"
                                    [data]="genders"
                                    [(ngModel)]="selectedGender"
                                    formControlName="selectedGender"
                                    [settings]="singledropdownSettings"
                                    (onSelect)="selectGender($event, 'select')"
                                    (onDeSelect)="selectGender($event, 'deselect')"
                                    [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                        <div *ngIf="f.gender.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">

            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Image * (552x552)</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/speakers'"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
        </div>
    </div>
</div>