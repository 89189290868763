<div class="row mb-3">
    <div class="col-md-12">
        <a class="btn btn-primary btn-rounded float-right" [routerLink]="'/' + url + '/create'">Créer une catégorie</a>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
    <!--- Complete Table -->
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped border mt-4">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Catégories</th>
                            <th scope="col" class="text-right col-actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of records?.data">
                            <td>{{ record.id }}</td>
                            <td>{{ record.name }}</td>
                            <td align="right">
                                <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="edit(record.id)"><i class="icon-pencil"></i></button>
                                <button type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2" (click)="delete(record.id)"><i class="icon-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true" (pageChange)="getAll($event)"></ngb-pagination>
            </div>
        </div>
    </div>
</div>