<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped border mt-4" style="min-width: 1000px;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Conseillés</th>
                <th scope="col">Particuliers</th>
                <th scope="col">Date</th>
                <th scope="col">Fait par </th>
                <th scope="col" class="text-right col-actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of reportedAppointments?.data; let i = index">
                <td>
                  <input id="c{{record.id}}" type="checkbox" class="d-inline-block ml-0" [value]="record.id"
                    (change)="onCheckboxChange($event, record.id)">
                  <span class="d-inline-block ml-2">{{ record?.id }}</span>
                </td>
                <td class="img-radius align-top">
                  <img class="mr-2 float-left"
                    src="{{ environment.uploadPath }}users/{{ record?.appointment?.counselor?.image }}"
                    class="mr-2 rounded-circle float-left" width="40px" height="40px">
                  <div class="float-left">
                    <h5 class="m-b-0 font-16 font-medium">
                      {{ record?.appointment?.counselor?.firstname }}<br />{{ record?.appointment?.counselor?.lastname }}
                    </h5>
                  </div>
                </td>
                <td class="img-radius align-top">
                  <img *ngIf="record?.appointment?.individual" class="mr-2 float-left"
                    src="{{ environment.uploadPath }}users/{{ record?.appointment?.individual?.image }}"
                    class="mr-2 rounded-circle float-left" width="40px" height="40px">
                  <div *ngIf="record?.appointment?.individual" class="float-left">
                    <h5 class="m-b-0 font-16 font-medium">
                      {{ record?.appointment?.individual?.firstname }}<br />{{ record?.appointment?.individual?.lastname }}
                    </h5>
                  </div>
                </td>
                <td>
                  <span><i class="icon-calender"></i> {{ record?.appointment?.due_date | date:'shortDate' }}</span><br />
                  <span><i class="icon-clock"></i> {{ record?.appointment?.due_date | date:'shortTime' }}</span>
                </td>
                <td class="img-radius align-top">
                  <img *ngIf="record?.user" class="mr-2 float-left"
                    src="{{ environment.uploadPath }}users/{{ record?.user?.image }}" class="mr-2 rounded-circle float-left"
                    width="40px" height="40px">
                </td>
                <td align="right">
                  <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" *ngIf="record?.to_appointment_id == 0;"
                    (click)="showReason(reasonModal,record?.reason )"><i
                      class="icon-bubble"></i></button>
                  <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" *ngIf="record?.to_appointment_id == 0;"
                    (click)="open(content,record?.id, record?.appointment?.counselor?.id, i )"><i
                      class="fas fa-exchange-alt"></i></button>
                  <button type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2" (click)="delete(record?.id)"><i
                      class="icon-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination class="d-flex justify-content-center" [collectionSize]="reportedAppointments?.total" [rotate]="true"
            [maxSize]="10" [(page)]="currentPage" [pageSize]="reportedAppointments?.per_page" [boundaryLinks]="true"
            (pageChange)="getAllReportedAppointments($event)"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Changer la date de consultation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="newForm">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="exampleSelect1">Date de début de fourchette</label>
          <input formControlName="dateBegin" type="date" class="form-control" id="date1">

        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="exampleSelect2">Date de fin de fourchette</label>
          <input formControlName="dateEnd" type="date" class="form-control" id="date2">
        </div>
        <div class="col-lg-4 col-md-6 form-group mt-4">
          <div class="row">
            <div class="col-6">
              <button class="btn btn-secondary btn-rounded mx-3" (click)="reinitialiser()">Réinitialiser</button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary btn-rounded mx-3" (click)="onSubmit()">Filtrer</button>
            </div>
          </div>
        </div>
      </form>
    <div class="table-responsive">
        <table class="table table-striped border mt-4" style="min-width: 600px;">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Conseillés</th>
              <th scope="col">Date</th>
              <th scope="col" class="text-right col-actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let record of counselorAppointments?.data">
              <td>
                <input id="c{{record.id}}" type="checkbox" class="d-inline-block ml-0"  [value]="record.id" (change)="onCheckboxChange($event, record.id)">
                <span class="d-inline-block ml-2">{{ record?.id }}</span>
              </td>
              <td class="img-radius align-top">
                <img class="mr-2 float-left" src="{{ environment.uploadPath }}users/{{ record?.counselor?.image }}" class="mr-2 rounded-circle float-left" width="40px" height="40px">
                <div class="float-left">
                  <h5 class="m-b-0 font-16 font-medium">{{ record?.counselor?.firstname }}<br/>{{ record?.counselor?.lastname }}</h5>
                </div>
              </td>
              <td>
                <span><i class="icon-calender"></i> {{ record?.due_date | date:'shortDate' }}</span><br />
                <span><i class="icon-clock"></i> {{ record?.due_date | date:'shortTime' }}</span>
              </td>
              <td align="right">
                <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="changeTo(record?.id)"><i
                    class="fas fa-exchange-alt"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="counselorAppointments?.total" [rotate]="true"
          [maxSize]="10" [(page)]="currentPage2" [pageSize]="counselorAppointments?.per_page" [boundaryLinks]="true"
          (pageChange)="getCounselorAppointments( counselorAppointments?.data[0]?.counselor?.id,$event)"></ngb-pagination>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>

<ng-template #reasonModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Raison</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12" [innerHtml]="reasonToShow"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>
