<div class="row mb-3">
    <div class="col-md-12">
        <a class="btn btn-primary btn-rounded float-right" [routerLink]="'/' + url + '/create'">Créer un modèle de contenu</a>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped border mt-4">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Titre</th>
                                <th scope="col" class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let help of records?.data">
                                <td>{{ help?.id }}</td>
                                <td>{{ help?.title }}</td>
                                <td width="150" align="right">
                                    <button type="button" class="btn btn-warning btn-circle mr-2 nb-2" (click)="edit(help?.id)"><i class="far fa-edit"></i></button>
                                    <button type="button" class="btn btn-danger btn-circle mr-2 nb-2" (click)="delete(help?.id)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true" (pageChange)="getAll()"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>