import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../services/api/api.service';
import { environment } from '../../../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-email-contact-detail',
  templateUrl: './email-contact-detail.component.html',
  styleUrls: ['./email-contact-detail.component.css']
})
export class EmailContactDetailComponent implements OnInit {
  environment = environment;
  id: any = null;
  model = "emailContacts";

  record ;
  memberships ;
  newForm: FormGroup;
  submitted = false;
  myControl = new FormControl();

  
  selectedFilePath : any;
  /* Formulaire */
  // editUser = new FormGroup({
  //   title: new FormControl('', Validators.required),
  //   message: new FormControl('', Validators.required),
  //   image: new FormControl( '', Validators.required),
  // });
  editUser: FormGroup;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '100px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
    this.getCategories();
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res) => {
        this.record = res;
        console.log("this.record",this.record);
        this.setNewForm();
      })
    }
  }

  getCategories()
  {
    this.api.get('users/allAdminsNoPag').subscribe( (data ) => 
      {
        console.log('data', data);
        this.memberships = data;

      })
  }



  private initNewForm() {

    this.newForm = new FormGroup({
      user_id: new FormControl('', []),
      answer: new FormControl('', []),
      message: new FormControl('', []),
    });
  }

  private setNewForm() {
    console.log(' im in set ');
    this.newForm.get("user_id").setValue(this.record.user.id);
    this.newForm.get("message").setValue(this.record.message);
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    recordToSave.append('message', this.f.message.value);
    if(this.id)
    {
      recordToSave.append('id', this.id);
      recordToSave.append('message', this.f.answer.value);
    };
    recordToSave.append('user_id', this.f.user_id.value);
 

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model + '/answer', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été répondue.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/send', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été envoyé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

}
