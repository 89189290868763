  <div class="row">
    <div class="col-lg-4 col-xlg-3 col-md-5">
      <div class="card">
        <div class="card-body">
  
          <div class="row">
            <div [class.col-4]="!record?.email_verified_at" [class.col-6]="record?.email_verified_at">
              <a type="button" routerLink="/individuals" class="btn btn-block btn-rounded btn-secondary float-left text-white">Retour</a>
            </div>
            <div class="col-4" *ngIf="!record?.email_verified_at">
              <a type="button" (click)="validate()" class="btn btn-block btn-rounded btn-info float-right text-white">Valider</a>
            </div>
            <div [class.col-4]="!record?.email_verified_at" [class.col-6]="record?.email_verified_at">
              <a type="button" (click)="delete()" class="btn btn-block btn-rounded btn-danger float-right text-white">Supprimer</a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="m-t-30 text-center">
            <img src="{{ environment.uploadPath }}users/{{ record?.image }}" class="rounded-circle" width="150px"
              height="150px" />
            <h4 class="card-title m-t-10">{{ record?.firstname }} {{ record?.lastname | uppercase }}</h4>
            <h6 class="card-subtitle">Inscrit le {{ record?.created_at | date:'d/MM/yyyy' }}</h6>
          </div>
        </div>
        <div>
          <hr>
        </div>
        <div class="card-body">
          <small class="text-muted">Adresse email </small>
          <h6>{{ record?.email }}</h6>
          <small class="text-muted p-t-30 db">Téléphone</small>
          <h6>{{ record?.tel }}</h6>
          <small class="text-muted p-t-30 db">Île</small>
          <h6>{{ record?.town?.island?.name }}</h6>
          <small class="text-muted p-t-30 db">Commune</small>
          <h6>{{ record?.town?.name }}</h6>
          <small class="text-muted p-t-30 db">Adresse</small>
          <h6>{{ record?.address }}</h6>
        </div>
        <div>
          <hr>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xlg-9 col-md-7">
      <div class="card">
        <div class="card-body">
          <ngb-tabset type="pills" class="custom-pills">
            <ngb-tab title="Info" *ngIf="record?.reporting !== null">
              <ng-template ngbTabContent>
                <div class="card-body">
                  <div class="col-md-12 col-xs-12"> 
                    <h5 class="mt-3 mb-3"> Courte description : </h5>
                    <div class="row">
                      <p class="text-muted m-0 p-1">
                        {{ record?.short_description }}
                      </p>
                    </div>
                    <hr>
                    <h5 class="mt-3 mb-3"> Longue description : </h5>
                    <div class="row">
                      <p class="text-muted text-justify m-0 p-1">
                        {{ record?.long_description }}
                      </p>
                    </div>
                    <hr>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab title="Consultations">
              <ng-template ngbTabContent>
                <div class="card-body">
                  <div class="col-md-12 col-xs-12 b-r">
                    <div id="infos-section">
                      <div class="content-section">
                        <div class="table-responsive">
                          <table class="table">
                            <tr class="header">
                              <th  class="black-color">Client</th>
                              <th  class="black-color">Date/heure</th>
                              <th  class="black-color">Status</th>
                            </tr>
                            <tr *ngFor="let session of record?.appointments" style="vertical-align:middle">
                              <td>
                                <img class="mr-2 rounded-circle float-left" src="{{ environment.uploadPath }}users/{{ session?.counselor.image }}"
                                alt="" width="60px" height="60px">
                                <div >
                                  <h5  class="m-b-0 font-medium ">{{ session?.counselor.lastname }} <br>  {{ session?.counselor.firstname }}</h5>
                                </div>
                              </td>
                              <td style="vertical-align:middle">
                                {{ session?.date | date: 'd MMMM y'}} à <br>
                                {{ session?.time }}
                              </td>
                              <td>
                                {{session?.status_appointment?.name}}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>  
                    </div>
                    <hr class="m-0">
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
    <notifier-container></notifier-container>
  