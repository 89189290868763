<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>
</ul>
        <!-- ============================================================== -->
        <!-- Right side toggle and nav items -->
        <!-- ============================================================== -->
        <ul class="navbar-nav float-right">
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <li class="nav-item" ngbDropdown placement="bottom-right">
                <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <img src="{{ environment.uploadPath }}users/{{ image }}" alt="user" class="rounded-circle" width="31">
                </a>
                <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
                    <span class="with-arrow">
                        <span class="bg-primary"></span>
                    </span>
                    <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                        <div class="">
                            <img src="{{ environment.uploadPath }}users/{{ image }}" alt="user" class="img-circle" width="60">
                        </div>
                        <div class="m-l-10">
                            <h4 class="m-b-0">{{ lastname }} {{ firstname }}</h4>
                            <p class=" m-b-0">{{ email }}</p>
                        </div>
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)">
                    <i class="ti-user m-r-5 m-l-5"></i> Mon Profil</a>
                    <a class="dropdown-item" routerLink="/emailContacts">
                    <i class="ti-email m-r-5 m-l-5"></i> Messagerie</a>
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="logout()" style="cursor: pointer;">
                    <i class="fa fa-power-off m-r-5 m-l-5"></i> Déconnexion</a>
                </div>
            </li>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
        </ul>