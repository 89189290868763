<div class="row mb-3">
    <div class="col-md-12">
        <a class="btn btn-primary btn-rounded float-right" [routerLink]="'/' + url + '/create'">Créer une conférence</a>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
    <!--- Complete Table -->
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped border mt-4">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Titre</th>
                            <th scope="col">Programmé le</th>
                            <th scope="col">Auteurs</th>
                            <th scope="col">Abonnement</th>
                            <!-- <th scope="col">Inscrits</th> -->
                            <th scope="col">Vues</th>
                            <th scope="col" class="text-right col-actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of records?.data">
                            <td>{{ record.id }}</td>
                            <td>
                                <img class="mr-2 float-left" src="{{ environment.uploadPath }}conferences/{{ record.image }}" alt="" width="75px">
                                <div class="float-left">
                                    <h5 class="m-b-0 font-16 font-medium">{{ record.title }}</h5>
                                    <span [class]="'badge mr-2' + ((record.published == 1) ? ' badge-success' : ' badge-secondary')">Publié</span>
                                    <span [class]="'badge mr-2' + ((record.novelty == 1) ? ' badge-success' : ' badge-secondary')">Nouveauté</span>
                                </div>
                            </td>
                            <td>
                                <span><i class="icon-calender"></i> {{ record.date_due_to | date:'d/M/yyyy' }}</span><br/>
                                <span><i class="icon-clock"></i> {{ record.hour_due_to }}</span>
                            </td>
                            <td>
                                <a class="btn-circle btn p-0 popover-item" href="javascript:void(0)" *ngFor="let user of record.authors" ngbTooltip="{{ user.lastname + ' ' + user.firstname }}">
                                    <img src="{{ environment.uploadPath }}users/{{ user.image }}" class="rounded-circle" width="39" alt="" />
                                </a>
                            </td>
                            <td>{{ record.membership?.title }}</td>
                            <!-- <td></td> -->
                            <td>{{ record.views }}</td>
                            <td align="right">
                                <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="edit(record.id)"><i class="icon-pencil"></i></button>
                                <button type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2" (click)="delete(record.id)"><i class="icon-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true" (pageChange)="getAll($event)"></ngb-pagination>
            </div>
        </div>
    </div>
</div>