<div class="row">
  <div class="col-lg-4 col-xlg-3 col-md-5">
    <div class="card">
      <div class="card-body">

        <div class="row">
          <div class="col">
            <a type="button" routerLink="/counsellors"
              class="btn btn-rounded btn-secondary float-left text-white">Retour</a>
          </div>
          <div class="col">
            <a type="button" (click)="delete()" class="btn btn-rounded btn-danger float-right text-white">Supprimer</a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="m-t-30 text-center">
          <img src="{{ environment.uploadPath }}users/{{ user?.image }}" class="rounded-circle" width="150px"
            height="150px" />
          <h4 class="card-title m-t-10">{{ user?.firstname }} {{ user?.lastname }}</h4>
          <h6 class="card-title m-t-10">{{ user?.nickname }}</h6>
          <h6 class="card-subtitle">Inscrit le {{ user?.created_at | date:'d/MM/yyyy' }}</h6>
        </div>
        <div class="m-t-30 text-center">
          <a type="button" (click)="activateCounselor()" class="btn btn-rounded btn-primary text-white"
            *ngIf="user?.activated == 3">Activer le compte</a>
        </div>
      </div>
      <div>
        <hr>
      </div>
      <div class="card-body">
        <small class="text-muted">Adresse email </small>
        <h6>{{ user?.email }}</h6>
        <small class="text-muted p-t-30 db">IBAN</small>
        <h6>{{ user?.iban }}</h6>
      </div>
      <div>
        <hr>
      </div>
      <div class="card-body">
        <small class="text-muted">Tarif </small>
        <h6>{{ user?.pricing }}€</h6>
      </div>
      <div>
        <hr>
      </div>
      <div class="card-body">
        <small class="text-muted">Lien video </small><br/>
        <input type="text" value="{{ user?.video }}" class="w-100 form-control" #videoLink />
        <button class="btn btn-block bg-primary btn-rounded text-white mt-3" (click)="updateVideo(videoLink.value)">Enregistrer</button>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-xlg-9 col-md-7">
    <div class="card">
      <div class="card-body">
        <ngb-tabset type="pills" class="custom-pills" (tabChange)="loadDataForTab($event)">
          <ngb-tab title="Info">
            <ng-template ngbTabContent>
              <div class="card-body">
                <div class="col-md-12 col-xs-12">
                  <h5 class="mt-3 mb-3"> Courte description : </h5>
                  <div class="row">
                    <p class="text-muted m-0 p-1">
                      {{ user?.short_description }}
                    </p>
                  </div>
                  <hr>
                  <h5 class="mt-3 mb-3"> Longue description : </h5>
                  <div class="row">
                    <p class="text-muted text-justify m-0 p-1">
                      {{ user?.long_description }}
                    </p>
                  </div>
                  <hr>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Profil">
            <ng-template ngbTabContent>
              <div class="card-body">
                <div class="col-md-12 col-xs-12 b-r">
                  <h5 class="mt-3 mb-3"> Mes diplomes : </h5>
                  <div class="row">
                    <div class="col-lg-12">
                      <ul>
                        <li *ngFor="let diplome of user?.diplomas_user">
                          <a href="{{environment.uploadPath + 'diplomas/' + diplome?.file }}" target="_blank">
                            {{ diplome?.category_diploma?.name }} - {{ diplome?.speciality}}<br />
                            obtenu le {{ diplome?.date_obtained | date:'shortDate'}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr>
                  <h5 class="mt-3 mb-3"> Mes langues : </h5>
                  <div class="row">
                    <p class="text-muted m-0 col-4 p-1" *ngFor="let language of user?.language_user">
                      {{ language?.name }}
                    </p>
                  </div>
                  <hr>
                  <h5 class="mt-3 mb-3"> Mes spécialités : </h5>
                  <div class="row">
                    <div class="text-muted m-0 col-4 p-1" *ngFor="let speciality of user?.specialities">
                      {{ speciality?.name }}
                    </div>
                  </div>
                  <hr>
                  <h5 class="mt-3 mb-3"> Mes compétences : </h5>
                  <div class="row">
                    <div class="text-muted m-0 col-4 p-1" *ngFor="let problematic of user?.problematic_user">
                      {{ problematic?.name }}
                    </div>
                  </div>
                  <hr>
                  <h5 class="mt-3 mb-3"> Mes approches théoriques : </h5>
                  <div class="row">
                    <p class="text-muted m-0 col-4 p-1" *ngFor="let ta of user?.theorical_approaches">
                      {{ ta?.name }}
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="cons" title="Consultations">
            <ng-template ngbTabContent>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-4 col-md-12">
                    <div class="card border-bottom border-orange">
                      <div class="card-body">
                        <div class="d-flex no-block align-items-center">
                          <div>
                            <h2>{{user?.appointments_reserved_count}} </h2>
                            <h6 class="text-orange">Consultations réservées</h6>
                          </div>
                          <div class="ml-auto">
                            <span class="text-orange display-6">
                              <i class="ti-clipboard"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="card border-bottom border-purple">
                      <div class="card-body">
                        <div class="d-flex no-block align-items-center">
                          <div>
                            <h2>{{user?.appointments_done_count}} </h2>
                            <h6 class="text-purple">Consultations terminées</h6>
                          </div>
                          <div class="ml-auto">
                            <span class="text-purple display-6">
                              <i class="ti-clipboard"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="card border-bottom border-cyan">
                      <div class="card-body">
                        <div class="d-flex no-block align-items-center">
                          <div>
                            <h2>{{user?.appointments_paid_count}}</h2>
                            <h6 class="text-cyan">Consultations Payées</h6>
                          </div>
                          <div class="ml-auto">
                            <span class="text-cyan display-6">
                              <i class="ti-clipboard"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-6 form-group">
                    <label for="exampleSelect2">Statuts</label>
                    <select (change)="statusAppointmentId = $event.target.value; currentPage = 1; total = 0; checkArray = []; getAllAppointmentsAuthCounselorsByStatus()" class="form-control w-100" id="exampleSelect2">
                      <option>-- Choissisez un statut --</option>
                      <option value="0">Tous les statut</option>
                      <option value="1">Libre</option>
                      <option value="2">Réservée</option>
                      <option value="3">Terminée</option>
                      <option value="4">Reportée</option>
                      <option value="5">Rémunérée</option>
                      <option value="6">Annulée</option>

                    </select>
                  </div>
                </div>
                <div class="row mt-2" *ngIf="showInfos">
                  <div class="col-lg-6 col-md-12 form-group" *ngIf="tabFilter[1]">
                    <button class="btn btn-primary btn-block" (click)="setMassAppointmentToPaid()">Total à rémunérer : {{total}}€</button>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <button class="btn btn-secondary btn-rounded btn-block" (click)="setMassAppointmentToPaid()"><i class="fas fa-dollar-sign"></i> Rémunérer</button>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <button class="btn btn-danger btn-rounded btn-block" (click)="deleteMassAppointment()"><i class="icon-trash"></i> Supprimer</button>
                  </div>
                </div>

                <div class="col-md-12 col-xs-12 b-r">
                  <div id="infos-section">
                    <div class="content-section">
                      <div class="table-responsive">
                        <table class="table" style="min-width: 1000px;">
                          <thead>
                            <tr class="header">
                              <th class="black-color">#</th>
                              <th class="black-color">Client</th>
                              <th class="black-color">Date/heure</th>
                              <th class="black-color">Status</th>
                              <th class="black-color">Prix</th>
                              <th class="black-color text-right">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let session of appointments?.data">
                              <td>
                                <input id="c{{session.id}}" type="checkbox" class="d-inline-block ml-0" [value]="session.id" (change)="onCheckboxChange($event, session.id)">
                                <span class="d-inline-block ml-2">{{ session?.id }}</span>
                              </td>
                              <td class="img-radius align-top">
                                <img class="mr-2 rounded-circle float-left" *ngIf="session?.individual" src="{{ environment.uploadPath }}users/{{ session?.individual?.image }}" alt="" width="60px" height="60px">
                                <div *ngIf="session?.individual" >
                                  <h5 class="m-b-0 font-medium ">{{ session?.individual?.lastname }} <br> {{ session?.individual?.firstname }}</h5>
                                </div>
                              </td>
                              <td>
                                <span><i class="icon-calender"></i> {{ session?.due_date | date:'dd/MM/yyyy' }}</span><br />
                                <span><i class="icon-clock"></i> {{ session?.due_date | date:'HH:mm' }}</span>
                              </td>
                              <td>
                                <span class="badge mr-2" [ngClass]="getBgStatus(session?.status_appointment?.id)" style="width: 65px;">{{ session?.status_appointment?.name }}</span> 
                              </td>
                              <td>
                                <span class="">Conseiller : {{ session?.pricing }}€</span><br/>
                                <span class="">Familipsy : {{ roundPrice(session?.pricing * 0.2) }}€</span><br/>
                                <span class="">Taxe : {{ roundPrice((session?.pricing * 1.2) * 0.13) }}€</span><br/>
                              </td>
                              <td align="right">
                                <!-- <button type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="edit(session?.id)"><i class="icon-pencil"></i></button> -->
                                <button *ngIf="session?.status_appointment_id == 3" type="button" class="btn btn-circle btn-outline-secondary mr-2 mb-2" (click)="setAppointmentToPaid(session?.id)" ><i class="fas fa-dollar-sign"></i></button>
                                <button type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2" (click)="deleteAppointment(session?.id)"><i class="icon-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="appointments?.total" [rotate]="true"
                          [maxSize]="10" [(page)]="currentPage" [pageSize]="appointments?.per_page" [boundaryLinks]="true"
                          (pageChange)="getAllAppointmentsAuthCounselorsByStatus($event)"></ngb-pagination>
                      </div>
                    </div>
                  </div>
                  <hr class="m-0">
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Zoom">
            <ng-template ngbTabContent>
              <div class="card-body">
                <div class="col-md-12 col-xs-12 b-r">
                  <form class="b-form" [formGroup]="zoomForm">

                    <div class="form-group row">
                      <div class="col-sm-3">
                        <div class="b-label">
                          <label for="inputEmail3" class="control-label col-form-label">Adresse email ZOOM</label>
                        </div>
                      </div>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="inputEmail3" formControlName="email_zoom"
                          placeholder="Adresse email ZOOM"
                          [ngClass]="{ 'is-invalid': submitted && f.email_zoom.errors }">
                        <div *ngIf="submitted && f.email_zoom.errors" class="invalid-feedback">
                          <div *ngIf="f.email_zoom.errors.required">Le champs est requis</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-3">
                        <div class="b-label">
                          <label for="inputEmail3" class="control-label col-form-label">Mot de passe ZOOM</label>
                        </div>
                      </div>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="inputEmail3" formControlName="password_zoom"
                          placeholder="Mot de passe ZOOM"
                          [ngClass]="{ 'is-invalid': submitted && f.password_zoom.errors }">
                        <div *ngIf="submitted && f.password_zoom.errors" class="invalid-feedback">
                          <div *ngIf="f.password_zoom.errors.required">Le champs est requis</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="user?.secret_code">
                      <div class="col-sm-3">
                        <div class="b-label">
                          <label for="inputEmail3" class="control-label col-form-label">Lien de réunion
                            personnel</label>
                        </div>
                      </div>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="inputEmail3" formControlName="url_visio"
                          placeholder="Lien de réunion personnel"
                          [ngClass]="{ 'is-invalid': submitted && f.url_visio.errors }">
                        <div *ngIf="submitted && f.url_visio.errors" class="invalid-feedback">
                          <div *ngIf="f.url_visio.errors.required">Le champs est requis</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="user?.activated">
                      <div class="col-sm-3">
                        <div class="b-label">
                          <label for="inputEmail3" class="control-label col-form-label">Code de réunion</label>
                        </div>
                      </div>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" id="inputEmail3" formControlName="secret_code"
                          placeholder="Code de réunion" [ngClass]="{ 'is-invalid': submitted && f.secret_code.errors }">
                        <div *ngIf="submitted && f.secret_code.errors" class="invalid-feedback">
                          <div *ngIf="f.secret_code.errors.required">Le champs est requis</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="!user?.secret_code && user?.activated">
                      <div class="col">
                        <button class="form-control btn btn-rounded btn-primary text-white" type="button"
                          (click)="updateSecretCode()">Mettre à jour le code secret</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <!-- <ngb-tab title="Agenda" *ngIf="user?.reporting !== null">
              <ng-template ngbTabContent>
                <div class="card-body">
                  <div class="col-md-12 col-xs-12 b-r">
                    <div class="row text-center">
                      <div class="col-md-4">
                        <div class="btn-group">
                          <div
                            class="btn btn-primary"
                            mwlCalendarPreviousView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()"
                          >
                            Previous
                          </div>
                          <div
                            class="btn btn-outline-secondary"
                            mwlCalendarToday
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()"
                          >
                            Today
                          </div>
                          <div
                            class="btn btn-primary"
                            mwlCalendarNextView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()"
                          >
                            Next
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <h3>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
                      </div>
                      <div class="col-md-4">
                        <div class="btn-group">
                          <div
                            class="btn btn-primary"
                            (click)="setView('Month')"
                            >
                            Month
                          </div>
                          <div
                            class="btn btn-primary"
                            (click)="setView('Week')"
                          
                          >
                            Week
                          </div>
                          <div
                            class="btn btn-primary"
                            (click)="setView('Day')"
                            
                          >
                            Day
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    
                    <div [ngSwitch]="view">
                      <mwl-calendar-month-view
                        *ngSwitchCase="'month'"
                        [viewDate]="viewDate"
                        [events]="events"
                        (dayClicked)="dayClicked($event.day)"
                      >
                      </mwl-calendar-month-view>
                      <mwl-calendar-week-view
                        *ngSwitchCase="'week'"
                        [viewDate]="viewDate"
                        [events]="events"
                      >
                      </mwl-calendar-week-view>
                      <mwl-calendar-day-view
                        *ngSwitchCase="'day'"
                        [viewDate]="viewDate"
                        [events]="events"
                      >
                      </mwl-calendar-day-view>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab> -->
        </ngb-tabset>
      </div>
    </div>
  </div>
  <notifier-container></notifier-container>
