import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  msg = '';
  constructor(
    private auth: AuthService,
    private routes: Router,
    private notifier: NotifierService
  ) { }

  loginform = true;
  recoverform = false;

  ngOnInit(){
    if(this.auth.isAuthenticated()) this.routes.navigate(['dashboard']);
  }

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  login(email: string, password: string) {
    if (email == "" || password == "") {
      console.log("error");
      this.notifier.notify( "error", "Veulliez verifier les champs obligatoires" );
      return;
    }
    this.auth.login({email: email, password: password});
  }

  reset(email: string) {
    if (email == "") {
      console.log("error");
      this.notifier.notify( "error", "Veulliez verifier une adresse email" );
      return;
    }
    this.notifier.notify( "warning", "Service indisponible pour le moment" );
  }
}
