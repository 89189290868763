import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Tableau de bord',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Applications',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/trainings',
    title: 'Formations',
    icon: 'icon-notebook',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/conferences',
    title: 'Conférences',
    icon: 'icon-calender',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/appointments',
    title: 'Consultations',
    icon: 'ti-clipboard',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/reported-appointments',
    title: 'Report',
    icon: 'fas fa-reply',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/feedbacks',
    title: 'Avis',
    icon: 'icon-star',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Contenu',
    icon: 'icon-menu',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/slides',
    title: 'Diaporamas',
    icon: 'icon-layers',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/faqs',
    title: 'F.A.Q',
    icon: 'icon-question',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/quotes',
    title: 'Citations',
    icon: 'fas fa-quote-right',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/partners',
    title: 'Partenaires',
    icon: 'fas fa-handshake',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/files',
    title: 'Ressources',
    icon: 'icon-folder-alt',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/pages',
    title: 'Pages',
    icon: 'icon-notebook',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/contents',
    title: 'Contenu',
    icon: 'fas fa-align-left',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/testimonies',
    title: 'Témoignages',
    icon: 'icon-people',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Paramètres',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/categories',
    title: 'Catégories',
    icon: 'icon-list',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/tags',
    title: 'Tags',
    icon: 'fas fa-hashtag',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/problematics',
    title: 'Problématiques',
    icon: 'fas fa-bug',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/specialities',
    title: 'Spécialités',
    icon: 'icon-directions',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/theoricalApproaches',
    title: 'Approches Théoriques',
    icon: 'icon-book-open',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/countries',
    title: 'Pays',
    icon: 'icon-flag',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/languages',
    title: 'Langues',
    icon: 'icon-globe',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Utilisateurs',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/memberships',
    title: 'Abonnements',
    icon: 'icon-tag',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/individuals',
    title: 'Particuliers',
    icon: 'icon-user',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/counsellors',
    title: 'Conseillers',
    icon: 'icon-badge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/speakers',
    title: 'Conférenciers',
    icon: 'icon-graduation',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/administrators',
    title: 'Administrateurs',
    icon: 'mdi-account-settings-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Messageries',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/emailContents',
    title: 'Modèles de mails',
    icon: 'icon-envelope-open',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/emailContacts',
    title: 'Messagerie',
    icon: 'icon-bubbles',
    class: '',
    extralink: false,
    submenu: []
  },
];
