import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-reported-appointment-list',
  templateUrl: './reported-appointment-list.component.html',
  styleUrls: ['./reported-appointment-list.component.css']
})
export class ReportedAppointmentListComponent implements OnInit {
  environment = environment;
  reportedAppointments;
  newForm: FormGroup;
  url = 'reported_appointments';
  currentPage = 1;

  url2 = 'counselor/appointments/';
  currentPage2 = 1;

  reasonToShow = "";
  offset: any;

  constructor(
    private api : ApiService,
    private notifier : NotifierService,
    private modalService : NgbModal,
    
  ) { }

  ngOnInit(): void {
    this.offset = new Date().getTimezoneOffset();
    this.getAllReportedAppointments();
    this.initNewForm();
  }

  getBgStatus(id) {
    switch(id){
      case 1: return "badge-secondary"; //Libre
      case 2: return "badge-warning"; //Réservée
      case 3: return "badge-primary"; //Terminée
      case 4: return "badge-info"; //Reportée
      case 5: return "badge-success"; //Rémunéréz,
      case 6: return "badge-danger"; //Annulée
    }
  }

 
  getAllReportedAppointments(page = 0){
    const url = this.url + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe( data => {
      this.reportedAppointments = data;
      this.reportedAppointments.data.forEach((item,key)=>{
        let explD = item.appointment.due_date.split(" ")[0].split("-");
        let explT = item.appointment.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (this.offset * 60000));
        this.reportedAppointments.data[key].appointment.due_date = dueDate;
      })
      console.log("this.reportedAppointments", this.reportedAppointments);
      
    })
  }

  closeResult = '';
  myCounselorId;
  myReportedAppointmentId;
  myIndex;
  options = {
    filter : false,
    dateBegin : 0,
    dateEnd : 0
  };
  counselorAppointments;
  open(content, reportedAppointmentId, counselorId, index) {
    this.myIndex = index;
    this.myCounselorId = counselorId;
    this.myReportedAppointmentId = reportedAppointmentId;
    this.getCounselorAppointments(this.myCounselorId);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size : 'xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  showReason(content, reason) {
    this.reasonToShow = reason;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size : 'xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getCounselorAppointments(id, page = 0){
    const url = this.url2 + id + ((page) ? ("?page=" + page) : "");
    this.api.post(url, this.options).subscribe(res => {
      this.counselorAppointments = res;

      this.counselorAppointments.data.forEach((item,key)=>{
        let explD = item.due_date.split(" ")[0].split("-");
        let explT = item.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (this.offset * 60000));
        this.counselorAppointments.data[key].due_date = dueDate;
      })
      console.log('this.counselorAppointments', this.counselorAppointments);
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  reinitialiser(){
    this.options.filter = false;
    this.options.dateBegin = 0;
    this.options.dateEnd = 0;
    this.initNewForm();
    this.getCounselorAppointments(this.myCounselorId);
  }

  get f() { return this.newForm.controls; }


  private initNewForm() {

    this.newForm = new FormGroup({
      dateBegin: new FormControl('', [Validators.required]),
      dateEnd: new FormControl('', [Validators.required]),
    });
  }

  onSubmit() {
    
    if(!this.newForm.valid){
      this.notifier.notify('warning', 'Veuillez insérer deux date ou aucune!');
      return;
    }else{
      this.options.filter = true;
      this.options.dateBegin = this.f.dateBegin.value;
      this.options.dateEnd = this.f.dateEnd.value;
    }
    
    this.getCounselorAppointments(this.myCounselorId);
  }

  changeTo(id){
    const formData = new FormData();

    formData.append('from_appointment_id', this.reportedAppointments.data[this.myIndex].from_appointment_id);
    formData.append('to_appointment_id', id);
    formData.append('reason', this.reportedAppointments.data[this.myIndex].reason);

    this.api.post('update/reported_appointment/' + this.myReportedAppointmentId, formData).subscribe( data => {
      this.notifier.notify('success', "Les emails ont bien été envoyés pour notifier les concernés du changement effectué!")
      this.getCounselorAppointments(this.myCounselorId);
      
    })
  }
}
