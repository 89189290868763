<div class="row">
    <div class="col-md-12">
        <!--- Complete Table -->
        <div class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped border mt-4" style="min-width: 1000px;">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Conseillés</th>
                            <th scope="col">Particuliers</th>
                            <th scope="col">Date</th>
                            <th scope="col">Note</th>
                            <th scope="col">Statut</th>
                            <th scope="col" class="text-right col-actions">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of records?.data">
                                <td>
                                    <span class="d-inline-block ml-2">{{ record?.id }}</span>
                                </td>
                                <td class="img-radius align-top">
                                    <img class="mr-2 float-left" src="{{ environment.uploadPath }}users/{{ record?.counselor?.image }}" class="mr-2 rounded-circle float-left" width="40px" height="40px">
                                    <div class="float-left">
                                        <h5 class="m-b-0 font-16 font-medium">{{ record?.counselor?.firstname }}<br/>{{ record?.counselor?.lastname }}</h5>
                                    </div>
                                </td>
                                <td class="img-radius align-top">
                                    <img *ngIf="record?.user" class="mr-2 float-left" src="{{ environment.uploadPath }}users/{{ record?.user?.image }}" class="mr-2 rounded-circle float-left" width="40px" height="40px">
                                    <div *ngIf="record?.user" class="float-left">
                                        <h5 class="m-b-0 font-16 font-medium">{{ record?.user?.firstname }}<br/>{{ record?.user?.lastname }}</h5>
                                    </div>
                                </td>
                                <td>
                                    <span><i class="icon-calender"></i> {{ record?.appointment?.due_date | date:'shortDate' }}</span><br />
                                    <span><i class="icon-clock"></i> {{ record?.appointment?.due_date | date:'shortTime' }}</span>
                                </td>
                                <td>
                                    <i class="fa-star" [class.text-secondary-light]="record?.rate<1" [class.text-warning]="record?.rate>=1" [class.far]="record?.rate<1" [class.fas]="record?.rate>=1"></i>
                                    <i class="fa-star" [class.text-secondary-light]="record?.rate<2" [class.text-warning]="record?.rate>=2" [class.far]="record?.rate<2" [class.fas]="record?.rate>=2"></i>
                                    <i class="fa-star" [class.text-secondary-light]="record?.rate<3" [class.text-warning]="record?.rate>=3" [class.far]="record?.rate<3" [class.fas]="record?.rate>=3"></i>
                                    <i class="fa-star" [class.text-secondary-light]="record?.rate<4" [class.text-warning]="record?.rate>=4" [class.far]="record?.rate<4" [class.fas]="record?.rate>=4"></i>
                                    <i class="fa-star" [class.text-secondary-light]="record?.rate<5" [class.text-warning]="record?.rate>=5" [class.far]="record?.rate<5" [class.fas]="record?.rate>=5"></i>
                                </td>
                                <td>
                                    <span *ngIf="record?.status == 0" class="badge badge-warning">En attente</span>
                                    <span *ngIf="record?.status == 1" class="badge badge-success">Validé</span>
                                    <span *ngIf="record?.status == 2" class="badge badge-danger">Invalidé</span>
                                </td>
                                <td align="right">
                                    <button type="button" class="btn btn-circle btn-outline-secondary mr-2 mb-2" (click)="showMessage(messageModal, record?.message)" ><i class="fas fa-comment-alt"></i></button>
                                    <button *ngIf="record?.status == 0" type="button" class="btn btn-circle btn-outline-info mr-2 mb-2" (click)="validateFeedback(record?.id)"><i class="fas fa-eye"></i></button>
                                    <button *ngIf="record?.status == 0" type="button" class="btn btn-circle btn-outline-danger mr-2 mb-2" (click)="unvalidateFeedback(record?.id)"><i class="fas fa-eye-slash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true"
                    [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true"
                    (pageChange)="getAll($event)"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #messageModal let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ message }}
    </div>
</ng-template>
  