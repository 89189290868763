import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { environment } from '../../../../environments/environment';

import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {
  environment = environment;
  
  private notifier: NotifierService;
  records: any;
  url = "contents";
  currentPage = 1;
  constructor(
    private api : ApiService,
    notifier: NotifierService,
    private router: Router
    ) { 
      this.notifier = notifier;
    }

  ngOnInit(): void {
    this.getAll();
  }

  getAll()
  {
    this.api.get(this.url).subscribe((data) => {    
      this.records = data;
      console.log(data);
    })
  }

  edit(id) {
    console.log('url', this.url);
    this.router.navigate([this.url+'/detail/' + id]);
  }

  delete(id) {
    this.api.get( "delete/content/" + id).subscribe(res => {
      console.log('res', res);
      this.notifier.notify('success',"La ressource a bien été supprimée");
      this.getAll();
    });
  }


}
