import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { environment } from '../../../../environments/environment';

import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-contact-list',
  templateUrl: './email-contact-list.component.html',
  styleUrls: ['./email-contact-list.component.css']
})
export class EmailContactListComponent implements OnInit {

  environment = environment;
  
  private notifier: NotifierService;
  records =  [];
  url = "emailContacts";
  currentPage = 1;    
  toShow;
  showButton = true;
  reply = false;
  newForm: FormGroup;
  submitted;
  emailContactId;
  emailContentPage = 1;

  constructor(
    private api : ApiService,
    notifier: NotifierService,
    private router: Router
    ) { 
      this.notifier = notifier;
    }

  ngOnInit(): void {
    this.getAll();
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      message: new FormControl('', [Validators.required]),
    });
  }

  getAll() {
    const url = this.url + "?page=" + this.emailContentPage;
    
    this.api.get(url).subscribe((data: any) => {    
      // this.records = data;
      this.records.push(...data.data);
      console.log(data);
    })
  }

  edit(id) {
    console.log('url', this.url);
    this.router.navigate([this.url+'/detail/' + id]);
  }

  delete(id) {
    this.api.delete(this.url + "/" + id).subscribe(res => {
      console.log('res', res);
      this.toShow = null;
      this.notifier.notify('success',"Le message a bien été supprimée");
      this.records = this.records.filter(item=>item.id != id);
      // this.getAll();
    });
  }

  setMessageZone(id, index) {
    this.emailContactId = id;
    this.api.get(this.url +"/"+ id).subscribe(data => {
      this.records[index].read_at = new Date();
      console.log('datamessage', data);
      this.toShow = data;
      this.reply = false;
      this.showButton = true;
    })
  }

  showForm()
  {
    this.reply = true;
    this.showButton = false;
    this.initNewForm();
  }

  cancel()
  {
    this.reply = false;
    this.showButton = true;
  }

  send()
  {
    this.onSubmit();
  }

  get f() { return this.newForm.controls; }


  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();   
    recordToSave.append('message', this.f.message.value);
    recordToSave.append('id', this.emailContactId);
    // recordToSave.append('user_id', this.f.user_id.value);
 

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
      this.api.post('emailContacts/send', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été envoyé.");
        // this.router.navigate(["records"]);
      })
    
  }

  onScroll() {
    console.log('scrolled!!');
    this.emailContentPage++;
    this.getAll();
  }

}
