import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { QuillModule } from 'ngx-quill';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ConferenceDetailComponent } from './pages/conferences/conference-detail/conference-detail.component';
import { ConferenceListComponent } from './pages/conferences/conference-list/conference-list.component';
import { TrainingDetailComponent } from './pages/trainings/training-detail/training-detail.component';
import { TrainingListComponent } from './pages/trainings/training-list/training-list.component';
import { SlideListComponent } from './pages/slides/slide-list/slide-list.component';
import { SlideDetailComponent } from './pages/slides/slide-detail/slide-detail.component';
import { QuoteListComponent } from './pages/quotes/quote-list/quote-list.component';
import { QuoteDetailComponent } from './pages/quotes/quote-detail/quote-detail.component';



import { ValuesPipe } from './pipes/values.pipe';
import { ResourceListComponent } from './pages/resources/resource-list/resource-list.component';
import { ResourceDetailComponent } from './pages/resources/resource-detail/resource-detail.component';
import { ResourceCategoryListComponent } from './pages/resources/resource-category-list/resource-category-list.component';
import { ResourceCategoryDetailComponent } from './pages/resources/resource-category-detail/resource-category-detail.component';
import { CategoryListComponent } from './pages/categories/category-list/category-list.component';
import { CategoryDetailComponent } from './pages/categories/category-detail/category-detail.component';
import { TagListComponent } from './pages/tags/tag-list/tag-list.component';
import { TagDetailComponent } from './pages/tags/tag-detail/tag-detail.component';
import { TheoricalApproachListComponent } from './pages/theorical-approaches/theorical-approach-list/theorical-approach-list.component';
import { TheoricalApproachDetailComponent } from './pages/theorical-approaches/theorical-approach-detail/theorical-approach-detail.component';
import { SpecialityListComponent } from './pages/specialities/speciality-list/speciality-list.component';
import { SpecialityDetailComponent } from './pages/specialities/speciality-detail/speciality-detail.component';
import { ProblematicListComponent } from './pages/problematics/problematic-list/problematic-list.component';
import { ProblematicDetailComponent } from './pages/problematics/problematic-detail/problematic-detail.component';
import { ProblematicCategoryListComponent } from './pages/problematics/problematic-category-list/problematic-category-list.component';
import { ProblematicCategoryDetailComponent } from './pages/problematics/problematic-category-detail/problematic-category-detail.component';
import { MembershipListComponent } from './pages/memberships/membership-list/membership-list.component';
import { MembershipDetailComponent } from './pages/memberships/membership-detail/membership-detail.component';
import { SpeakerListComponent } from './pages/users/speakers/speaker-list/speaker-list.component';
import { SpeakerDetailComponent } from './pages/users/speakers/speaker-detail/speaker-detail.component';
import { AdministratorDetailComponent } from './pages/users/administrators/administrator-detail/administrator-detail.component';
import { AdministratorListComponent } from './pages/users/administrators/administrator-list/administrator-list.component';
import { CounselorListComponent } from './pages/users/counselors/counselor-list/counselor-list.component';
import { CounselorDetailComponent } from './pages/users/counselors/counselor-detail/counselor-detail.component';
import { IndividualListComponent } from './pages/users/individuals/individual-list/individual-list.component';
import { IndividualDetailComponent } from './pages/users/individuals/individual-detail/individual-detail.component';
import { FaqListComponent } from './pages/faqs/faq-list/faq-list.component';
import { FaqDetailComponent } from './pages/faqs/faq-detail/faq-detail.component';
import { FaqCategoryListComponent } from './pages/faqs/faq-category-list/faq-category-list.component';
import { FaqCategoryDetailComponent } from './pages/faqs/faq-category-detail/faq-category-detail.component';
import { PagesListComponent } from './pages/cms-pages/pages-list/pages-list.component';
import { PagesDetailComponent } from './pages/cms-pages/pages-detail/pages-detail.component';
import { PartnersListComponent } from './pages/partners/partners-list/partners-list.component';
import { PartnersDetailComponent } from './pages/partners/partners-detail/partners-detail.component';
import { CountryListComponent } from './pages/countries/country-list/country-list.component';
import { CountryDetailComponent } from './pages/countries/country-detail/country-detail.component';
import { LanguageDetailComponent } from './pages/languages/language-detail/language-detail.component';
import { LanguageListComponent } from './pages/languages/language-list/language-list.component';
import { EmailContentListComponent } from './pages/emailContents/email-content-list/email-content-list.component';
import { EmailContentDetailComponent } from './pages/emailContents/email-content-detail/email-content-detail.component';
import { ContentListComponent } from './pages/contents/content-list/content-list.component';
import { ContentDetailComponent } from './pages/contents/content-detail/content-detail.component';
import { EmailContactListComponent } from './pages/emailContacts/email-contact-list/email-contact-list.component';
import { EmailContactDetailComponent } from './pages/emailContacts/email-contact-detail/email-contact-detail.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppointmentListComponent } from './pages/appointments/appointment-list/appointment-list.component';

import { TestimonyListComponent } from './pages/testimonies/testimony-list/testimony-list.component';
import { TestimonyDetailComponent } from './pages/testimonies/testimony-detail/testimony-detail.component';
import { TestimonyCategoryListComponent } from './pages/testimonies/testimony-category-list/testimony-category-list.component';
import { TestimonyCategoryDetailComponent } from './pages/testimonies/testimony-category-detail/testimony-category-detail.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FeedbackListComponent } from './pages/feedbacks/feedback-list/feedback-list.component';
import { ReportedAppointmentListComponent } from './pages/reportedAppointments/reported-appointment-list/reported-appointment-list.component';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};


/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
        position: 'right',
        distance: 12
    },
    vertical: {
        position: 'top',
        distance: 12,
        gap: 10
    }
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    LoginComponent,
    DashboardComponent,
    ConferenceDetailComponent,
    ConferenceListComponent,
    TrainingDetailComponent,
    TrainingListComponent,
    SlideListComponent,
    SlideDetailComponent,
    QuoteListComponent,
    QuoteDetailComponent,
    ValuesPipe,
    ResourceListComponent,
    ResourceDetailComponent,
    ResourceCategoryListComponent,
    ResourceCategoryDetailComponent,
    CategoryListComponent,
    CategoryDetailComponent,
    TagListComponent,
    TagDetailComponent,
    TheoricalApproachListComponent,
    TheoricalApproachDetailComponent,
    SpecialityListComponent,
    SpecialityDetailComponent,
    ProblematicListComponent,
    ProblematicDetailComponent,
    ProblematicCategoryListComponent,
    ProblematicCategoryDetailComponent,
    MembershipListComponent,
    MembershipDetailComponent,
    SpeakerListComponent,
    SpeakerDetailComponent,
    AdministratorDetailComponent,
    AdministratorListComponent,
    CounselorListComponent,
    CounselorDetailComponent,
    IndividualListComponent,
    IndividualDetailComponent,
    FaqListComponent,
    FaqDetailComponent,
    FaqCategoryListComponent,
    FaqCategoryDetailComponent,
    PagesListComponent,
    PagesDetailComponent,
    PartnersListComponent,
    PartnersDetailComponent,
    CountryListComponent,
    CountryDetailComponent,
    LanguageDetailComponent,
    LanguageListComponent,
    EmailContentListComponent,
    EmailContentDetailComponent,
    ContentListComponent,
    ContentDetailComponent,
    EmailContactListComponent,
    EmailContactDetailComponent,
    AppointmentListComponent,
    TestimonyListComponent,
    TestimonyDetailComponent,
    TestimonyCategoryListComponent,
    TestimonyCategoryDetailComponent,
    FeedbackListComponent,
    ReportedAppointmentListComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: true }),
    PerfectScrollbarModule,
    NotifierModule.withConfig(customNotifierOptions),

    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    NgMultiSelectDropDownModule,
    ChartsModule,
    ChartistModule,
    AutocompleteLibModule,
    AngularEditorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    InfiniteScrollModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: LOCALE_ID, useValue: 'fr' },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
