import { Component, OnInit ,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../services/api/api.service';
import { environment } from '../../../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-partners-detail',
  templateUrl: './partners-detail.component.html',
  styleUrls: ['./partners-detail.component.css']
})
export class PartnersDetailComponent implements OnInit {
  @ViewChild('auto') auto;
  environment = environment;
  id: any = null;
  model = "partners";
  folder = "partenaires";

  record ;
  partners ;
  newForm: FormGroup;
  submitted = false;
  myControl = new FormControl();

  selectedFilePath: any;
  imageToUpload: any = null;
  update =  false;
  /* Formulaire */
  // editUser = new FormGroup({
  //   name: new FormControl('', Validators.required),
  //   desc: new FormControl('', Validators.required),
  //   image: new FormControl( '', Validators.required),
  // });
  editUser: FormGroup;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '300px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
    this.update = false;
    this.initNewForm();
    this.getPartners();
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res) => {
        this.record = res;
        console.log("this.record", res);
        this.selectedFilePath = environment.uploadPath + this.folder + '/' + this.record.image;
        this.update = true;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+image';
    }
  }

  getPartners()
  {
    this.api.get('list/partners').subscribe( (data ) => 
      {
        console.log('data', data);
        this.partners = data;

      })
  }

  private initNewForm() {
    if(!this.id)
    {
       this.newForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        desc: new FormControl('', [Validators.required]),
        sorting: new FormControl('', []),
      });
    }
    else
    {
      this.newForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        desc: new FormControl('', [Validators.required]),
        sorting: new FormControl('', []),
        newId: new FormControl('', []),
      });
    }
   
    
  }

  private setNewForm() {
    console.log(' im in set ');
    this.newForm.get("name").setValue(this.record.name);
    this.newForm.get("desc").setValue(this.record.desc);
    this.newForm.get("sorting").setValue(this.record.sorting);
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id)
    {
      recordToSave.append('id', this.id);
      recordToSave.append('newId', this.f.newId.value);
    }
      
    recordToSave.append('name', this.f.name.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);
    recordToSave.append('desc', this.f.desc.value);
 

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

}
