import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Category } from 'src/app/models/category/category.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.css']
})
export class CategoryDetailComponent implements OnInit {

  environment = environment;
  id: any = null;
  model = "categories";

  record: Category;
  newForm: FormGroup;
  submitted = false;

  selectedFilePath: any;
  imageToUpload: any = null;

  selectedFilePathPicto: any;
  pictoToUpload: any = null;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '200px',
      minHeight: '0px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Category) => {
        this.record = res;
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.record.image;
        this.selectedFilePathPicto = environment.uploadPath + this.model + '/' + this.record.picto;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+image';
      this.selectedFilePathPicto = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+picto';
    }
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  private setNewForm() {

    this.newForm.get("name").setValue(this.record.name);
    this.newForm.get("description").setValue(this.record.description);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  readPathPicto(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePathPicto = (<FileReader>event.target).result;
      }
      this.pictoToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('name', this.f.name.value);
    recordToSave.append('description', this.f.description.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);
    if(this.pictoToUpload) recordToSave.append('picto', this.pictoToUpload, this.pictoToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["resources"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

}