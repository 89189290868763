import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Slide } from './../../../models/slide/slide.model';

@Component({
  selector: 'app-slide-detail',
  templateUrl: './slide-detail.component.html',
  styleUrls: ['./slide-detail.component.css']
})
export class SlideDetailComponent implements OnInit {

  @ViewChild('file') file: ElementRef<HTMLElement>;
  environment = environment;
  id: any = null;
  model = "slides";

  record: Slide;

  selectedFilePath: any;
  imageToUpload: any = null;
  newForm: FormGroup;;

  submitted = false;

  selectedTextWrapper = 1;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Slide) => {
        this.record = res;
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.record.image;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+image';
    }
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      video: new FormControl('', []),
      image: new FormControl('', [Validators.required]),
      button: new FormControl('', []),
      link: new FormControl('', []),
      slug: new FormControl('', [Validators.required]),
      position_text: new FormControl('middle_middle', [Validators.required]),
      background: new FormControl('', []),
      sorting: new FormControl('', [Validators.required]),
    });
  }

  private setNewForm() {
    this.newForm.get('title').setValue(this.record.title);
    this.newForm.get('video').setValue(this.record.video);
    this.newForm.get('image').setValue(this.record.image);
    this.newForm.get('button').setValue(this.record.button);
    this.newForm.get('link').setValue(this.record.link);
    this.newForm.get('slug').setValue(this.record.slug);
    this.newForm.get('position_text').setValue(this.record.position_text);
    this.newForm.get('background').setValue(this.record.background);
    this.newForm.get('sorting').setValue(this.record.sorting);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('title', this.f.title.value);
    recordToSave.append('video', this.f.video.value);
    recordToSave.append('image', this.f.image.value);
    recordToSave.append('button', this.f.button.value);
    recordToSave.append('link', this.f.link.value);
    recordToSave.append('slug', this.f.slug.value);
    recordToSave.append('sorting', this.f.sorting.value);
    recordToSave.append('position_text', this.f.position_text.value);
    recordToSave.append('background', (this.f.background.value)?"1":"0");
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    if (this.id === null && this.imageToUpload === null) {
      this.notifier.notify('warning','Veulliez sélectionner une image');
      return;
    }
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }
}
