import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Quote } from 'src/app/models/quote/quote.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.css']
})
export class QuoteDetailComponent implements OnInit {

  @ViewChild('file') file: ElementRef<HTMLElement>;
  environment = environment;
  id: any = null;
  model = "quotes";

  quote: Quote;

  selectedFilePath: any;
  imageToUpload: any = null;
  newForm: FormGroup;;

  submitted = false;

  selectedTextWrapper = 1;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Quote) => {
        this.quote = res;
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.quote.image;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/1920x1080/C1C1C1/ffffff?text=Télécharger+image';
    }
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      content: new FormControl('', [Validators.required,]),
      author: new FormControl("",[]),
    });
  }

  private setNewForm() {
    this.newForm.get("content").setValue(this.quote.content);
    this.newForm.get("author").setValue(this.quote.author);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('content', this.f.content.value);
    recordToSave.append('author', this.f.author.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    if (this.id === null && this.imageToUpload === null) {
      this.notifier.notify('warning','Veulliez sélectionner une image');
      return;
    }
    const quote = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, quote).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["quotes"]);
      })
    } else {
      this.api.post(this.model + '/create', quote).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["quotes"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }
}
