import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../../services/api/api.service';
import { environment } from '../../../../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { setHours, setMinutes } from 'date-fns';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-counselor-detail',
  templateUrl: './counselor-detail.component.html',
  styleUrls: ['./counselor-detail.component.css'],
})
export class CounselorDetailComponent implements OnInit {
    environment = environment;
    id: any = null;
    model = "counsellors";

    urlCreate = "users/create/testimony";
    urlUpdate = "users/update/testimony"
    user ;
    newForm: FormGroup;
    zoomForm: FormGroup;
    submitted = false;
    myControl = new FormControl();
    state = false;

  /* Agenda part */
  view: CalendarView = CalendarView.Month;
  locale = "fr";
  viewDate: Date = new Date();
  activeDayIsOpen; boolean = true;
  events: CalendarEvent[] = [
    //  {
    //    title: 'No event end date',
    //    start: setHours(setMinutes(new Date(), 0), 3),
    //  },
    //  {
    //    title: 'No event end date',
    //    start: setHours(setMinutes(new Date(), 0), 5),
    //  },
  ];


  //***************************************************************** Barry stuff *************************************************************
  tabFilter = [false, true, false, false]; // tableau pour la gestion des filtres
  appointments: any;
  statusAppointmentId = 0;
  currentPage = 1;

  getAllAppointmentsAuthCounselorsByStatus(page = 0){
    const url = 'user/appointmentsCounselor/'+this.id + ((page) ? ("?page=" + page) : "");
    this.api.post(url, {statusAppointmentId : this.statusAppointmentId}).subscribe( data => {
      this.appointments = data;
      console.log("this.appointments test2", this.appointments);
      
    })

  }

test() {
  console.log("test");
}
setAppointmentToPaid(id){
  this.api.get('appointment_status/paid/' + id).subscribe(data=>{
    this.getAllAppointmentsAuthCounselorsByStatus();
    this.getuser();
  })
}

deleteAppointment(id) {
  this.api.get("delete/appointment/" + id).subscribe(res => {
    console.log('res', res);
    this.notifier.notify('success',"l'élément a bien été supprimé");
    this.getAllAppointmentsAuthCounselorsByStatus();
    this.getuser();
  });
}


loadDataForTab(event){
  if(event.nextId == 'cons')
    this.getAllAppointmentsAuthCounselorsByStatus();
}

checkArray = [];
showInfos = false;
onCheckboxChange(e,who) {      
  if (e.target.checked) {
    this.checkArray.push(who);

  } else {
    const index = this.checkArray.indexOf(who);

    if (index > -1) {
      this.checkArray.splice(index, 1);
    }
      
  }
  this.showInfos = (this.checkArray.length != 0) ? true : false;
  console.log("checkArray", this.checkArray);
  console.log("showInfos", this.showInfos);
  (this.showInfos) ? this.findElementInAppointments() : this.total = 0;
  
}

myIndex = [];
total = 0;
findElementInAppointments(){
  this.myIndex = [];
  let cpt;
  for(var i = 0; i < this.tabFilter.length; i++){
    if(this.tabFilter[i]) cpt = i;
  }

  if(cpt == 1){
    this.checkArray.forEach(element => {
      const toto = this.appointments.data.map(function(e) { return e.id; }).indexOf(element);
      this.myIndex.push(toto);
      console.log("this.myIndex", this.myIndex);
    });
      this.total = 0;
      for(var i = 0; i < this.appointments.data.length; i++){
        this.myIndex.forEach(element => {
          if(element == i) this.total = this.total + this.appointments.data[i].pricing;
        });
      }
  }

}

deleteMassAppointment(){
  const data = {
    ids : this.checkArray
  }
  this.api.post('delete_mass/appointment', data).subscribe( res => {
    this.getAllAppointmentsAuthCounselorsByStatus();
    this.getuser();
    this.flushAllArray();
  })
}

setMassAppointmentToPaid(){
  const data = {
    ids : this.checkArray
  }
    this.api.post('mass_appointment_status/paid', data).subscribe( res => {
      this.getAllAppointmentsAuthCounselorsByStatus();
      this.getuser();
      this.flushAllArray();
    })
  
}


flushAllArray(){
  this.checkArray = [];
  this.myIndex = [];
}


 //***************************************************************** end Barry stuff *************************************************************


  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this.initZoomForm();
    this._activatedRoute.paramMap.subscribe(param => {      
      this.id = param.get('id');
      console.log('id', this.id);
    });
  }

  ngOnInit(): void {
    console.log('view', this.view);
    this.getuser();
  }

  validate(id, state) {
    
  }

  getuser() {
    if (this.id != null) {
      this.api.get("users/counselor/id/"+ this.id).subscribe((res) => {
        this.user = res;
        console.log("this.user",this.user);
        
        var cpt = 0;
        this.setNewForm();
        this.setZoomForm();
      })
    }
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      testimony: new FormControl('', [Validators.required]),
      user_id: new FormControl('', []),

    });
  }

  initZoomForm() {
    this.zoomForm = new FormGroup({
      email_zoom: new FormControl({value:'', disabled: true}, []),
      password_zoom: new FormControl({value:'', disabled: true}, []),
      secret_code: new FormControl('',[]),
      url_visio: new FormControl({value:'', disabled: true}, []),
    })
  }

  private setNewForm() {
    console.log(' im in set ');
    this.newForm.get("testimony").setValue(this.user.testimony);
    this.newForm.get("user_id").setValue(this.user.id);
    this.state = true;
  }

  private setZoomForm() {
    console.log('setZoomForm');
    this.zoomForm.get("email_zoom").setValue(this.user.email_zoom);
    this.zoomForm.get("password_zoom").setValue(this.user.password_zoom);
    this.zoomForm.get("secret_code").setValue(this.user.secret_code);
    if(this.user.secret_code) this.zoomForm.get("secret_code").disable({onlySelf:true});
    this.zoomForm.get("url_visio").setValue(this.user.url_visio);
  }

  get f() { return this.newForm.controls; }
  get z() { return this.zoomForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.user.id);
    recordToSave.append('testimony', this.f.testimony.value);
    recordToSave.append('user_id', this.f.user_id.value);
 
    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.urlUpdate+ "/" + this.user.id, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      // this.api.post(this.urlCreate + "/" + this.id, record).subscribe(res => {
      //   this.notifier.notify('success',"L'élément a bien été créé.");
      //   // this.router.navigate(["records"]);
      // })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.user.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

  setView(view) {
    switch (view) {
      case 'Month':
          var form = CalendarView.Month;
        break;
    
      case 'Day':
          var form = CalendarView.Day;
        break;
      
      case 'Week':
          var form = CalendarView.Week
    }
    this.view = form;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    console.log('in dayclicked', date);
      this.viewDate = date;
      this.view = CalendarView.Day;
  }
  
  


  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  activateCounselor(){
    this.api.get('activateCounselor/'+this.user.id).subscribe(data=>{
      console.log("activateCounselor", data);
      this.getuser();
    })
  }

  updateSecretCode(){
    this.api.post('updateSecretCode/'+this.user.id,{secret_code:this.z.secret_code.value}).subscribe(data=>{
      console.log("updateSecretCode", data);
      this.getuser();
    })
  }

  updateVideo(videoLink) {
    console.log("videoLink", videoLink);
    this.api.post("user/" + this.user.id + "/updateVideo", {video: videoLink}).subscribe(data=>{
      this.notifier.notify('success',"Le lien vidéo a bien été enregistré");
    })
  }

  roundPrice(number) {
    return Math.ceil(number);
  }

  getBgStatus(id) {
    switch(id){
      case 1: return "badge-secondary"; //Libre
      case 2: return "badge-warning"; //Réservée
      case 3: return "badge-primary"; //Terminée
      case 4: return "badge-info"; //Reportée
      case 5: return "badge-success"; //Rémunéréz,
      case 6: return "badge-danger"; //Annulée
    }
  }
}
