<!-- Row -->
<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom border-orange">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{totalCounselors}} </h2>
                        <h6 class="text-orange">Conseillers</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-orange display-6">
                            <i class="ti-user"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom border-success">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{totalDecouverte}} </h2>
                        <h6 class="text-success">Découvertes</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-success display-6">
                            <i class="ti-user"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom border-purple">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{totalSerenite}} </h2>
                        <h6 class="text-purple">Sérénités</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-purple display-6">
                            <i class="ti-user"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom bg-secondary-light border-secondary-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{countStatus?.count_free | number:'':'fr-FR'}}</h2>
                        <h6 class="">Consultations<br/>Libres</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom bg-info-light border-info-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{countStatus?.count_reserved | number:'':'fr-FR'}}</h2>
                        <h6 class="">Consultations<br/>Réservées</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom bg-primary-light border-primary-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{countStatus?.count_done | number:'':'fr-FR'}}</h2>
                        <h6 class="">Consultations<br/>Terminées</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom bg-warning-light border-warning-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{countStatus?.count_postponed | number:'':'fr-FR'}}</h2>
                        <h6 class="">Consultations<br/>Reportées</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom bg-success-light border-success-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{countStatus?.count_payed | number:'':'fr-FR'}}</h2>
                        <h6 class="">Consultations<br/>Rémunérées</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="card border-bottom bg-danger-light border-danger-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>{{countStatus?.count_canceled | number:'':'fr-FR'}}</h2>
                        <h6 class="">Consultations<br/>Annulées</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-lg-12 col-md-12">
        <div class="card">
            <div class="card-body">
                <!-- End Row -->
                <div class="row">
                    <!-- column -->
                    <div class="col-lg-4">
                        <h1 class="m-b-0 m-t-30 text-success">{{ ceilNumber(globalCA*0.2) | number:'':'fr-FR' }} €</h1>
                        <h6 class="font-light text-muted">Revenus Net</h6>
                        <h3 class="m-t-30 m-b-0 text-primary">{{ ceilNumber(ceilNumber(globalCA*1.2)*1.13) | number:'':'fr-FR' }} €</h3>
                        <h6 class="font-light text-muted">CA Global</h6>
                        <h3 class="m-t-30 m-b-0 text-info">{{ ceilNumber(globalCA) | number:'':'fr-FR' }} €</h3>
                        <h6 class="font-light text-muted">CA Conseillers</h6>
                        <h3 class="m-t-30 m-b-0 text-warning">{{ ceilNumber(ceilNumber(globalCA*1.2)*0.13) | number:'':'fr-FR' }} €</h3>
                        <h6 class="font-light text-muted">Taxes</h6>
                    </div>
                    <!-- column -->
                    <div class="col-lg-8">
                        <div class="linearea linechart" style="height: 300px;">
                            <x-chartist class="campaign ct-charts" [data]="lineChart?.data" [type]="lineChart?.type"
                                [options]="lineChart?.options" [responsiveOptions]="lineChart?.responsiveOptions"
                                [events]="lineChart?.events"> </x-chartist>
                        </div>
                    </div>
                    <!-- column -->
                </div>
            </div>
            <div class="card-body border-top">
                <div class="row m-b-0">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="d-flex align-items-center">
                            <div class="m-r-10">
                                <span class="text-orange display-5"><i class="fas fa-euro-sign"></i></span>
                            </div>
                            <div>
                                <span>CA Réservées</span>
                                <h3 class="font-medium m-b-0">{{ sum[2] | number:'':'fr-FR' }} €</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="d-flex align-items-center">
                            <div class="m-r-10">
                                <span class="text-cyan display-5"><i class="fas fa-euro-sign"></i></span>
                            </div>
                            <div>
                                <span>CA Terminées</span>
                                <h3 class="font-medium m-b-0">{{ sum[3] | number:'':'fr-FR' }} €</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="d-flex align-items-center">
                            <div class="m-r-10">
                                <span class="text-info display-5"><i class="fas fa-euro-sign"></i></span>
                            </div>
                            <div>
                                <span>CA Rémunérées</span>
                                <h3 class="font-medium m-b-0">{{ sum[5] | number:'':'fr-FR' }} €</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-lg-4 col-md-4">
        <div class="card">
            <div class="card-body">
                <div class="d-md-flex align-items-center">
                    <div>
                        <h4 class="card-title">Top Formations</h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table no-wrap v-middle">
                        <thead>
                            <tr class="border-0">
                                <th class="border-0">#</th>
                                <th class="border-0">Formation</th>
                                <th class="border-0">Vues</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let training of topTrainings" style="height: 95px;">
                                <td>{{training?.id}} </td>
                                <td>
                                    <div class="d-flex no-block align-items-center">
                                        <div class="m-r-10">
                                            <img [src]="environnment.uploadPath + 'trainings/' + training?.image" alt="user" class=""
                                            width="45" />
                                        </div>
                                        <div class="">
                                            <h5 class="m-b-0 font-16 font-medium">{{training?.title}} </h5>
                                        </div>
                                    </div>
                                </td>
                                <td>{{training?.views}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-4 col-md-4">
        <div class="card">
            <div class="card-body">
                <div class="d-md-flex align-items-center">
                    <div>
                        <h4 class="card-title">Top Conférences</h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table no-wrap v-middle">
                        <thead>
                            <tr class="border-0">
                                <th class="border-0">#</th>
                                <th class="border-0">Conférences</th>
                                <th class="border-0">Vues</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let conference of topConferences" style="height: 95px;">
                                <td>{{conference?.id}} </td>
                                <td>
                                    <div class="d-flex no-block align-items-center">
                                        <div class="m-r-10">
                                            <img [src]="environnment.uploadPath + 'conferences/' + conference?.image" alt="user" class=""
                                            width="45" />
                                        </div>
                                        <div class="">
                                            <h5 class="m-b-0 font-16 font-medium">{{ conference?.title }} </h5>
                                        </div>
                                    </div>
                                </td>
                                <td>{{conference?.views}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-4 col-md-4">
        <div class="card">
            <div class="card-body">
                <div class="d-md-flex align-items-center">
                    <div>
                        <h4 class="card-title">Top Conseillers</h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table no-wrap v-middle">
                        <thead>
                            <tr class="border-0">
                                <th class="border-0">#</th>
                                <th class="border-0">Conseillers</th>
                                <th class="border-0">CA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let counselor of topCounselors" style="height: 95px;">
                                <td>{{counselor?.id}} </td>
                                <td>
                                    <div class="d-flex no-block align-items-center">
                                        <div class="m-r-10">
                                            <img [src]="environnment.uploadPath + 'users/'+ counselor?.image" alt="user"
                                            class="rounded-circle" width="45" />
                                        </div>
                                        <div class="">
                                            <h5 class="m-b-0 font-16 font-medium">{{counselor?.lastname | uppercase}}
                                            {{counselor?.firstname | titlecase}}</h5>
                                        </div>
                                    </div>
                                </td>
                                <td>{{counselor?.appointments_ca_sum | number:'':'fr-FR'}} €</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
