<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Titre *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="title" placeholder="Titre" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                        <div *ngIf="f.title.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Auteur</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="author" placeholder="Sous-titre de la resource" [ngClass]="{ 'is-invalid': submitted && f.author.errors }">
                                    <div *ngIf="submitted && f.author.errors" class="invalid-feedback">
                                        <div *ngIf="f.author.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Catégories *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez une catégorie'"
                                    [data]="fileCategories"
                                    [(ngModel)]="selectedFileCategory"
                                    formControlName="selectedFileCategory"
                                    [settings]="singledropdownSettings"
                                    (onSelect)="selectFileCategory($event, 'select')"
                                    (onDeSelect)="selectFileCategory($event, 'deselect')"
                                    [ngClass]="{ 'is-invalid': submitted && f.file_category_id.errors }">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && f.file_category_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.file_category_id.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Abonnement</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez un abonnement'"
                                    [data]="memberships"
                                    [(ngModel)]="selectedMembership"
                                    formControlName="selectedMembership"
                                    [settings]="singledropdownMembershipsSettings"
                                    (onSelect)="selectMembership($event, 'select')"
                                    (onDeSelect)="selectMembership($event, 'deselect')">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Type *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez un type'"
                                    [data]="types"
                                    [(ngModel)]="selectedType"
                                    formControlName="selectedType"
                                    [settings]="singledropdownTypesSettings"
                                    (onSelect)="selectType($event, 'select')"
                                    (onDeSelect)="selectType($event, 'deselect')"
                                    [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                        <div *ngIf="f.type.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Url *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="value" placeholder="Sous-titre de la resource" [ngClass]="{ 'is-invalid': submitted && f.value.errors }">
                                    <input type="file" class="d-none" id="file" (change)="readPathFile($event)" #file>
                                    <div *ngIf="submitted && f.value.errors" class="invalid-feedback">
                                        <div *ngIf="f.value.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">

            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Image * (2550x3300)</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <p>Définissez une image représentative de votre ressource. Cette dernière sera utilisé à chaque affichage de votre ressource.</p>
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
    <!-- Row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Description *</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['description']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button *ngIf="resource?.id" class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
            <a *ngIf="resource?.id" href="{{ environment.siteUrl }}ressources/{{resource?.file_category?.slug}}/{{ resource?.slug }}" target="_blank" class="btn btn-rounded btn-primary float-left"><i class="icon-eye"></i> Aperçu</a>
        </div>
    </div>
</div>