<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped border mt-4">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Conseillés</th>
                                <th scope="col">Consultations</th>
                                <th scope="col">Gains</th>
                                <th scope="col" class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of records?.data">
                                <td>{{ record?.id }}</td>
                                <td class="img-radius align-top">
                                    <img class="mr-2 rounded-circle float-left" src="{{ environment.uploadPath }}users/{{ record?.image }}"
                                      alt="" width="60px" height="60px">
                                    <div>
                                      <h5 class="m-b-0 font-16 font-medium">{{ record?.lastname }} {{ record?.firstname }}</h5>
                                      <span>{{ record?.email }}</span><br />
                                      <span class="badge mr-2" [class.badge-success]="record?.email_verified_at" [class.badge-secondary]="!record?.email_verified_at">{{ record?.email_verified_at ? 'email vérifié' : 'email non vérifié' }}</span>
                                      <span class="badge" [class.badge-success]="record?.activated == 1" [class.badge-secondary]="record?.activated != 1">{{ record?.activated ? 'Compté activé' : 'Compté non activé' }}</span>
                                    </div>
                                </td>
                  
                                <td>
                                    Annulées : {{ record?.appointments_aborted_count }}<br/>
                                    Réservées : {{ record?.appointments_reserved_count }}<br/>
                                    Effectuées : {{ record?.appointments_done_count + record?.appointments_paid_count }}<br/>
                                    Rémunérées : {{ record?.appointments_paid_count }}<br/>
                                </td>
                                <td>
                                    Tarif : {{ record?.pricing }} €<br/>
                                    CA : {{ record?.appointments_ca_sum }} €<br/>
                                    À payer : {{ record?.appointments_done_sum }} €<br/>
                                    Rémunéré : {{ record?.appointments_paid_sum }} €<br/>
                                </td>
                                <td width="150" align="right">
                                    <button type="button" class="btn btn-warning btn-circle mr-2 nb-2" (click)="edit(record?.id)"><i class="far fa-edit"></i></button>
                                    <button type="button" class="btn btn-danger btn-circle mr-2 nb-2" (click)="delete(record?.id)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ngb-pagination class="d-flex justify-content-center" [collectionSize]="records?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="records?.per_page" [boundaryLinks]="true" (pageChange)="getAll()"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>