import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Memberships } from '../../../models/memberships/memberships.model';

@Component({
  selector: 'app-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.css']
})
export class MembershipListComponent implements OnInit {

  environment = environment;
  records: Memberships;
  url = "memberships";
  currentPage = 1;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService) { }

  ngOnInit() {
    this.getAll();
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  getAll(page = 0) {
    const url = this.url + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe((res: Memberships) => {
      this.records = res;
      console.log('this.records', this.records);
    });
  }

  edit(id) {
    this.router.navigate([this.url + '/detail/' + id]);
  }

  delete(id) {
    this.api.delete(this.url + "/" + id).subscribe(res => {
      console.log('res', res);
      this.notifier.notify('success','Le quote a bien été supprimé');
      this.getAll();
    });
  }
}
