<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col-6">
            <div class="row pt-0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header bg-muted">
                            <h4 class="m-b-0 text-white">Général</h4>
                        </div>
                        <hr class="m-t-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-lg-12 col-md-12">
                                    <div class="form-group row">
                                        <div class="col-sm-3">
                                            <div class="b-label">
                                                <label for="inputEmail3" class="control-label col-form-label">Nom *</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="inputEmail3" formControlName="name" placeholder="Titre" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required">Le champs est requis</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header bg-muted">
                            <h4 class="m-b-0 text-white">Description *</h4>
                        </div>
                        <hr class="m-t-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <angular-editor id="editor1" [formControl]="newForm.controls['description']" [config]="editorConfig"></angular-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Row -->
        </div>
        <div class="col-6">

            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Image * (258x258)</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <p>Définissez une image représentative de votre ressource. Cette dernière sera utilisé à chaque affichage de votre ressource.</p>
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Pictogramme * </h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <p>Définissez un pictogramme représentatif d'un graphique schématique. Cette dernière sera utilisé comme symbol pour l'affichage de votre ressource.</p>
                            <div class="form-group text-center">
                                <img [src]="selectedFilePathPicto" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="picto.click()" [ngClass]="{'error': submitted && pictoToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathPicto($event)" #picto>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
        </div>
    </div>
</div>