<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Nom *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="name" placeholder="Le nom du partenaire" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Image *</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Description *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <!-- <quill-editor style="height: 800px;" [formControl]="newForm.controls['content']"></quill-editor> -->
                                        <angular-editor id="editor1" formControlName="desc" [config]="editorConfig"></angular-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="update">
       
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="b-label col-sm">
                                    <label for="inputEmail3" class="control-label col-form-label">Échanger de positionnement de {{record.name}} avec</label>
                                </div>
                                <div class="col-sm-8">
                                    <select class="form-control" id="exampleFormControlSelect1" formControlName="newId">
                                        <option *ngFor="let partner of partners" value="{{partner.id}}"> {{partner.name}} ({{partner.sorting}}) </option>
                       
                                      </select>
                                    <div *ngIf="submitted && f.newId.errors" class="invalid-feedback">
                                        <div *ngIf="f.newId.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->  
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
            <a href="{{ environment.siteUrl }}{{ record?.slug }}" target="_blank" class="btn btn-rounded btn-primary float-left"><i class="icon-eye"></i> Aperçu</a>
        </div>
    </div>
</div>