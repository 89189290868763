import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Quotes } from 'src/app/models/quote/quotes.model';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.css']
})
export class QuoteListComponent implements OnInit {

  environment = environment;
  records: Quotes;
  url = "quotes";
  currentPage = 1;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService) { }

  ngOnInit() {
    this.getAll();
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  getAll(page = 0) {
    const url = this.url + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe((res: Quotes) => {
      this.records = res;
      console.log('this.quotes', this.records);
    });
  }

  edit(id) {
    this.router.navigate(['quotes/detail/' + id]);
  }

  delete(id) {
    this.api.delete(this.url + "/" + id).subscribe(res => {
      console.log('res', res);
      this.notifier.notify('success','Le quote a bien été supprimé');
      this.getAll();
    });
  }
}
