<!-- <div class="row mb-3">
    <div class="col-md-12">
        <a class="btn btn-primary btn-rounded float-right" [routerLink]="'/' + url + '/create'">Écrire un message</a>
    </div>
</div> -->

<div class="row">
    <div class="col-md-4"  style="max-height: 624px; overflow-y: scroll; " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
        <div class="card m-0 pointer border-bottom" *ngFor="let record of records; let i = index;" (click)="setMessageZone(record.id, i)">
            <div class="card-body" [class.bg-pink]="record?.id == toShow?.id">
                <span *ngIf="record?.read_at == null" class="position-absolute rounded-circle bg-pink" style="top: 39px; left: 10px; width: 10px; height: 10px;">&nbsp;</span>
                <div class="ml-3">
                    <h4 class="m-0">{{record?.lastname}} {{record?.firstname}}</h4>
                    <span><b>{{record?.subject}}</b></span><br/>
                    <span [class.text-muted]="record?.id != toShow?.id">{{ (record?.message.length>40)? (record?.message | slice:0:40)+'..':(record?.message) }}</span>
                </div>
                <span class="position-absolute text-right" [class.text-muted]="record?.id != toShow?.id" style="top: 20px; right: 20px;">{{record?.created_at | date:'dd/MM'}}</span>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="card">
            <div class="card-body" *ngIf="toShow">
                <div>
                    <div class="row pb-3 border-bottom">
                        <div class="col-lg-12">
                            <span><b>De :</b> {{toShow?.lastname}} {{toShow?.firstname}} </span><br/>
                            <span class="text-muted">{{toShow?.email}} </span><br/>
                            <span class="text-muted">{{toShow?.phone}} </span>
                        </div>
                        <div class="position-absolute" style="top: 20px; right: 20px;">
                            <i class="fas fa-reply mr-3 text-primary" style="font-size: 20px" (click)="showForm()"></i>
                            <i class="far fa-trash-alt text-danger" style="font-size: 20px" (click)="delete(toShow?.id)"></i>
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 border-bottom">
                        <div class="col-lg-12">
                            <span><b>Sujet :</b> {{toShow?.subject}} </span>
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 ">
                        <div class="col-lg-12">
                            <p class=""> {{toShow?.message}} </p>
                        </div>
                    </div>
                </div>
                <div class="mt-3" *ngIf="reply">
                    <form [formGroup]="newForm">
                        <textarea formControlName="message" id="w3mission" rows="4" placeholder="Tapez votre réponse" class="form-control"> </textarea>                    
                        <button type="button" class="btn btn-danger btn-rounded float-left mt-2" (click)="cancel()"> <i class="fas fa-ban"></i> Annuler  </button>
                        <button type="button" class="btn btn-primary btn-rounded float-right mt-2" (click)="send()"> Envoyer <i class="fas fa-paper-plane"></i> </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>