<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col">
                                    <div class="">
                                        <label for="inputEmail3" class="control-label">Nom *</label>
                                    </div>
                                    <div class="">
                                        <input type="text" class="form-control" id="inputEmail3" formControlName="title" placeholder="Titre de la conference" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                            <div *ngIf="f.title.errors.required">Le champs est requis</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group row">
                                <div class="col">
                                    <div class="">
                                        <label for="inputEmail3" class="control-label">Prix par mois *</label>
                                    </div>
                                    <div class="">
                                        <input type="text" class="form-control" id="inputEmail3" formControlName="monthly_price" placeholder="Prix par mois" [ngClass]="{ 'is-invalid': submitted && f.monthly_price.errors }">
                                        <div *ngIf="submitted && f.monthly_price.errors" class="invalid-feedback">
                                            <div *ngIf="f.monthly_price.errors.required">Le champs est requis</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col">
                                    <div class="">
                                        <label for="inputEmail3" class="control-label">Code Stripe (mensuel) *</label>
                                    </div>
                                    <div class="">
                                        <input type="text" class="form-control" id="inputEmail3" formControlName="monthly_stripe_plan" placeholder="Code Stripe (mensuel)" [ngClass]="{ 'is-invalid': submitted && f.monthly_stripe_plan.errors }">
                                        <div *ngIf="submitted && f.monthly_stripe_plan.errors" class="invalid-feedback">
                                            <div *ngIf="f.monthly_stripe_plan.errors.required">Le champs est requis</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group row">
                                <div class="col">
                                    <div class="">
                                        <label for="inputEmail3" class="control-label">Prix par an *</label>
                                    </div>
                                    <div class="">
                                        <input type="text" class="form-control" id="inputEmail3" formControlName="annualy_price" placeholder="Prix par an" [ngClass]="{ 'is-invalid': submitted && f.annualy_price.errors }">
                                        <div *ngIf="submitted && f.annualy_price.errors" class="invalid-feedback">
                                            <div *ngIf="f.annualy_price.errors.required">Le champs est requis</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col">
                                    <div class="">
                                        <label for="inputEmail3" class="control-label">Code Stripe (annuel) *</label>
                                    </div>
                                    <div class="">
                                        <input type="text" class="form-control" id="inputEmail3" formControlName="annualy_stripe_plan" placeholder="Code Stripe (annuel)" [ngClass]="{ 'is-invalid': submitted && f.annualy_stripe_plan.errors }">
                                        <div *ngIf="submitted && f.annualy_stripe_plan.errors" class="invalid-feedback">
                                            <div *ngIf="f.annualy_stripe_plan.errors.required">Le champs est requis</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">

            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Image * (512x512)</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
    
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Détails</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <button type="button" [class]="'btn btn-rounded mr-2 ' + ((selectedTextWrapper === 1) ? 'btn-secondary' : 'btn-outline-secondary')" (click)="selectedTextWrapper = 1" [ngClass]="{ 'is-invalid': submitted && f.intro.errors }">Introduction *</button>
                        <button type="button" [class]="'btn btn-rounded mr-2 ' + ((selectedTextWrapper === 2) ? 'btn-secondary' : 'btn-outline-secondary')" (click)="selectedTextWrapper = 2" [ngClass]="{ 'is-invalid': submitted && f.description.errors }">Description *</button>
                        <button type="button" [class]="'btn btn-rounded mr-2 ' + ((selectedTextWrapper === 3) ? 'btn-secondary' : 'btn-outline-secondary')" (click)="selectedTextWrapper = 3" [ngClass]="{ 'is-invalid': submitted && f.advantages.errors }">Avantages *</button>
                        <button type="button" [class]="'btn btn-rounded mr-2 ' + ((selectedTextWrapper === 4) ? 'btn-secondary' : 'btn-outline-secondary')" (click)="selectedTextWrapper = 4" [ngClass]="{ 'is-invalid': submitted && f.bullet_points.errors }">Bullet points *</button>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12" *ngIf="selectedTextWrapper === 1">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['intro']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-md-12" *ngIf="selectedTextWrapper === 2">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['description']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-md-12" *ngIf="selectedTextWrapper === 3">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['advantages']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-md-12" *ngIf="selectedTextWrapper === 4">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['bullet_points']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
        </div>
    </div>
</div>