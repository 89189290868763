<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <div class="card-body">
                    <div class="row" >
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Titre du modèle *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Le titre" formControlName="slug">
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Sujet de l'email *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="subject" placeholder="Le sujet" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                                    <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                        <div *ngIf="f.subject.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Contenu *</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <!-- <quill-editor style="height: 800px;" [formControl]="newForm.controls['content']"></quill-editor> -->
                                        <angular-editor id="editor1" formControlName="content" [config]="editorConfig"></angular-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->  
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
            <a href="{{ environment.siteUrl }}{{ record?.slug }}" target="_blank" class="btn btn-rounded btn-primary float-left"><i class="icon-eye"></i> Aperçu</a>
        </div>
    </div>
</div>