import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.css']
})
export class LanguageListComponent implements OnInit {
  environment = environment;
  records: any;
  url = "languages";
  currentPage = 1;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService) { }

  ngOnInit() {
    this.getAll();
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  getAll(page = 0) {
    const url = this.url + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe(res => {
      this.records = res;
      console.log('this.records', this.records);
    });
  }

  edit(id) {
    this.router.navigate([this.url+'/detail/' + id]);
  }

  delete(id) {
    this.api.delete(this.url + "/" + id).subscribe(res => {
      console.log('res', res);
      this.notifier.notify('success',"La ressource a bien été supprimée");
      this.getAll();
    });
  }


}
