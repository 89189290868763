<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-8">
                                    <div class="form-group row">
                                        <div class="col">
                                            <div class="">
                                                <label for="inputEmail3" class="control-label">Titre *</label>
                                            </div>
                                            <div class="">
                                                <input type="text" class="form-control" id="inputEmail3" formControlName="title" placeholder="Titre de la conference" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                                <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                                    <div *ngIf="f.title.errors.required">Le champs est requis</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col">
                                            <div class="">
                                                <label for="inputEmail3" class="control-label">Video</label>
                                            </div>
                                            <div class="">
                                                <input type="text" class="form-control" id="inputEmail3" formControlName="video" placeholder="Lien vidéo" [ngClass]="{ 'is-invalid': submitted && f.video.errors }">
                                                <div *ngIf="submitted && f.video.errors" class="invalid-feedback">
                                                    <div *ngIf="f.video.errors.required">Le champs est requis</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col">
                                            <div class="">
                                                <label for="inputEmail3" class="control-label">Bouton</label>
                                            </div>
                                            <div class="">
                                                <input type="text" class="form-control" id="inputEmail3" formControlName="button" placeholder="Bouton" [ngClass]="{ 'is-invalid': submitted && f.button.errors }">
                                                <div *ngIf="submitted && f.button.errors" class="invalid-feedback">
                                                    <div *ngIf="f.button.errors.required">Le champs est requis</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col">
                                            <div class="">
                                                <label for="inputEmail3" class="control-label">Link</label>
                                            </div>
                                            <div class="">
                                                <input type="text" class="form-control" id="inputEmail3" formControlName="link" placeholder="Lien" [ngClass]="{ 'is-invalid': submitted && f.link.errors }">
                                                <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
                                                    <div *ngIf="f.link.errors.required">Le champs est requis</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="">
                                                <label for="inputEmail3" class="control-label">Page *</label>
                                            </div>
                                            <div class="">
                                                <select class="custom-select" formControlName="slug" [ngClass]="{ 'is-invalid': submitted && f.slug.errors }">
                                                    <option>Choisissez une page de destination</option>
                                                    <option value="home">Accueil</option>
                                                    <option value="counselor">Conseiller</option>
                                                </select>
                                                <div *ngIf="submitted && f.slug.errors" class="invalid-feedback">
                                                    <div *ngIf="f.slug.errors.required">Le champs est requis</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="">
                                                <label for="inputEmail3" class="control-label">Position *</label>
                                            </div>
                                            <div class="">
                                                <input type="text" class="form-control" id="inputEmail3" formControlName="sorting" placeholder="Titre de la conference" [ngClass]="{ 'is-invalid': submitted && f.sorting.errors }">
                                                <div *ngIf="submitted && f.sorting.errors" class="invalid-feedback">
                                                    <div *ngIf="f.sorting.errors.required">Le champs est requis</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <p>Mise en page du contenu sur l'image</p>
                                            <table class="w-100" style="height: 100px;">
                                                <tbody>
                                                    <tr>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'left_top','bg-pink':newForm.get('position_text').value === 'left_top'}" (click)="newForm.get('position_text').setValue('left_top')" style="height:60px"></td>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'middle_top','bg-pink':newForm.get('position_text').value === 'middle_top'}" (click)="newForm.get('position_text').setValue('middle_top')" style="height:60px"></td>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'right_top','bg-pink':newForm.get('position_text').value === 'right_top'}" (click)="newForm.get('position_text').setValue('right_top')" style="height:60px"></td>
                                                    </tr>
                                                    <tr>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'left_middle','bg-pink':newForm.get('position_text').value === 'left_middle'}" (click)="newForm.get('position_text').setValue('left_middle')" style="height:60px"></td>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'middle_middle','bg-pink':newForm.get('position_text').value === 'middle_middle'}" (click)="newForm.get('position_text').setValue('middle_middle')" style="height:60px"></td>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'right_middle','bg-pink':newForm.get('position_text').value === 'right_middle'}" (click)="newForm.get('position_text').setValue('right_middle')" style="height:60px"></td>
                                                    </tr>
                                                    <tr>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'left_bottom','bg-pink':newForm.get('position_text').value === 'left_bottom'}" (click)="newForm.get('position_text').setValue('left_bottom')" style="height:60px"></td>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'middle_bottom','bg-pink':newForm.get('position_text').value === 'middle_bottom'}" (click)="newForm.get('position_text').setValue('middle_bottom')" style="height:60px"></td>
                                                      <td class="pointer align-middle text-center border border-light" [ngClass]="{'bg-muted':newForm.get('position_text').value !== 'right_bottom','bg-pink':newForm.get('position_text').value === 'right_bottom'}" (click)="newForm.get('position_text').setValue('right_bottom')" style="height:60px"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check">
                                              <input type="checkbox" class="form-check-input" id="background" formControlName="background">
                                              <label class="form-check-label" for="background">Ajouter un fond noir transparent entourant le titre et le bouton</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mt-5">
                                <div class="col">
                                    <div class="">
                                        <label for="inputEmail3" class="control-label">Image (1920x1080) *</label>
                                    </div>
                                    <div class="form-group text-center">
                                        <div *ngIf="newForm.get('video').value != '' || newForm.get('video').value != 'null'" class="slideContentText {{newForm.get('position_text').value}}" [ngClass]="{'background':newForm.get('background').value}">
                                            <h2>{{newForm.get('title').value}}</h2>
                                            <a href="javascript:void(0)" class="main-button-round" style="margin: 0 auto;">{{newForm.get('button').value}}</a>
                                        </div>
                                        <img [src]="selectedFilePath" style="width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                        <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
        </div>
    </div>
</div>