<router-outlet>
    <notifier-container></notifier-container>
    <ng-http-loader 
    [backdrop]="true"
    [backgroundColor]="'#ff0098'"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="1000"
    [opacity]="0.8"
    [spinner]="spinkit.skWanderingCubes">
</ng-http-loader>
</router-outlet>