import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../../environments/environment';
import { Speaker } from './../../../../models/user/speaker.model';

@Component({
  selector: 'app-speaker-detail',
  templateUrl: './speaker-detail.component.html',
  styleUrls: ['./speaker-detail.component.css']
})
export class SpeakerDetailComponent implements OnInit {

  @ViewChild('file') file: ElementRef<HTMLElement>;
  environment = environment;
  id: any = null;
  model = "users";

  record: Speaker;

  selectedFilePath: any;
  imageToUpload: any = null;
  newForm: FormGroup;

  submitted = false;

  singledropdownSettings = {};
  genders = [{code: 'male', name: 'Homme'},{code: 'female', name:'Femme'}];
  selectedGender = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getRecord();
    this.singledropdownSettings = {
      singleSelection: true,
      idField: 'code',
      textField: 'name',
      selectAllText: 'Sélectionner tout',
      unSelectAllText: 'Tout déselectionner',
      allowSearchFilter: false,
      closeDropDownOnSelection: false
    };
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/speaker/" + this.id).subscribe((res: Speaker) => {
        this.record = res;
        console.log("this.record", res);
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.record.image;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = environment.uploadPath + this.model + '/male.png';
    }
  }
  
  selectGender(item: any, type: any) {
    if(type === 'select'){
      this.selectedGender.push(item);
    } else {
      this.selectedGender = this.selectedGender.filter(function(value) { return value.name != item.name;})
    }
    
    this.newForm.get("gender").setValue(item.code);
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      lastname: new FormControl('', [Validators.required,]),
      firstname: new FormControl('', [Validators.required,]),
      gender: new FormControl('', [Validators.required,]),
      selectedGender: new FormControl('', []),
    });
  }

  private setNewForm() {
    

    if(this.record.gender){
      this.selectedGender = this.genders.filter(item => item.code === this.record.gender);
      this.newForm.get("selectedGender").setValue(this.selectedGender);
    }

    this.newForm.get("lastname").setValue(this.record.lastname);
    this.newForm.get("firstname").setValue(this.record.firstname);
    this.newForm.get("gender").setValue(this.record.gender);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('lastname', this.f.lastname.value);
    recordToSave.append('firstname', this.f.firstname.value);
    recordToSave.append('gender', this.f.gender.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    if (this.id === null && this.imageToUpload === null) {
      this.notifier.notify('warning','Veulliez sélectionner une image');
      return;
    }
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model + '/speaker/update', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/speaker/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/speaker/destroy/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }

  setCheckboxState(prop) {
    const newValue = (this.newForm.get(prop).value) ? 0 : 1;
    this.newForm.get(prop).setValue(newValue)
  }

}