<form class="b-form" [formGroup]="newForm">
    <!-- Row -->
    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Général</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Titre *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="title" placeholder="Titre de la conference" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                        <div *ngIf="f.title.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Sous-Titre *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" formControlName="subtitle" placeholder="Sous-titre de la conference" [ngClass]="{ 'is-invalid': submitted && f.subtitle.errors }">
                                    <div *ngIf="submitted && f.subtitle.errors" class="invalid-feedback">
                                        <div *ngIf="f.subtitle.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Catégories</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez une catégorie'"
                                    [data]="categories"
                                    [(ngModel)]="selectedCategory"
                                    formControlName="selectedCategory"
                                    [settings]="singledropdownSettings"
                                    (onSelect)="selectCategory($event, 'select')"
                                    (onDeSelect)="selectCategory($event, 'deselect')">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Abonnement</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez un abonnement'"
                                    [data]="memberships"
                                    [(ngModel)]="selectedMembership"
                                    formControlName="selectedMembership"
                                    [settings]="singledropdownMembershipsSettings"
                                    (onSelect)="selectMembership($event, 'select')"
                                    (onDeSelect)="selectMembership($event, 'deselect')">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <div class="b-label">
                                        <label for="inputEmail3" class="control-label col-form-label">Auteur *</label>
                                    </div>
                                </div>
                                <div class="col-sm-9">

                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez un/des auteur(s)'"
                                    [data]="authors"
                                    [(ngModel)]="selectedAuthors"
                                    formControlName="selectedAuthors"
                                    [settings]="dropdownSettings"
                                    (onSelect)="selectAuthor($event, 'select')"
                                    (onDeSelect)="selectAuthor($event, 'deselect')"
                                    (onSelectAll)="selectAllAuthors($event, 'select')"
                                    (onDeSelectAll)="selectAllAuthors($event, 'deselect')"
                                    [ngClass]="{ 'is-invalid': submitted && f.authors.errors }">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="submitted && f.authors.errors" class="invalid-feedback">
                                        <div *ngIf="f.authors.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                            <button type="button" [class]="'btn btn-rounded btn-block' + ((newForm.get('novelty').value === 1) ? ' btn-success' : ' btn-outline-secondary')" (click)="setCheckboxState('novelty')"><i class="fa fa-check"></i> Nouveautée</button>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-md-6">
                            <button type="button" [class]="'btn btn-rounded btn-block' + ((newForm.get('published').value === 1) ? ' btn-success' : ' btn-outline-secondary')" (click)="setCheckboxState('published')"><i class="fa fa-check"></i> Publier</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Image * (2550x3300)</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body" style="height: 470px;">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group text-center">
                                <img [src]="selectedFilePath" style="max-height: 400px; max-width: 100%; cursor: pointer; border:1px solid #fff" (click)="image.click()" [ngClass]="{'error': submitted && imageToUpload == null && id == null}" />
                                <input type="file" class="d-none" id="image" (change)="readPathImage($event)" #image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
    <!-- Row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Date</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-4 col-md-4">
                            <div class="form-group">
                                <label for="inputEmail3">Date *</label>
                                <input type="date" class="form-control" id="inputEmail3" placeholder="yyyy-mm-dd" formControlName="date_due_to" [ngClass]="{ 'is-invalid': submitted && f.date_due_to.errors }">
                                <div *ngIf="submitted && f.date_due_to.errors" class="invalid-feedback">
                                    <div *ngIf="f.date_due_to.errors.required">Le champs est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4 col-md-4">
                            <div class="form-group">
                                <label for="inputEmail3">Heure *</label>
                                <input type="text" class="form-control" id="inputEmail3" placeholder="hh:mm:ss" formControlName="hour_due_to" [ngClass]="{ 'is-invalid': submitted && f.hour_due_to.errors }">
                                <div *ngIf="submitted && f.hour_due_to.errors" class="invalid-feedback">
                                    <div *ngIf="f.hour_due_to.errors.required">Le champs est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4 col-md-4">
                            <div class="form-group">
                                <label for="inputEmail3">Durée *</label>
                                <input type="text" class="form-control" id="inputEmail3" placeholder="en minutes" formControlName="duration" [ngClass]="{ 'is-invalid': submitted && f.duration.errors }">
                                <div *ngIf="submitted && f.duration.errors" class="invalid-feedback">
                                    <div *ngIf="f.duration.errors.required">Le champs est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
    <!-- Row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Learny Box</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="inputEmail3">Reférence LearnyBox</label>
                                <div class="input-group">
                                    
                                    <ng-multiselect-dropdown
                                    [placeholder]="'Sélectionnez une référence learnybox'"
                                    [data]="conferencesLearnybox"
                                    [(ngModel)]="selectedLearnyboxId"
                                    formControlName="selectedLearnyboxId"
                                    [settings]="singledropdownLearnyboxIdSettings"
                                    (onSelect)="selectLearnyboxId($event, 'select')"
                                    (onDeSelect)="selectLearnyboxId($event, 'deselect')" class="w-100">
                                    </ng-multiselect-dropdown>
                                    
                                    <div *ngIf="submitted && f.learnybox_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.learnybox_id.errors.required">Le champs est requis</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="inputEmail3">Lien LearnyBox *</label>
                                <input type="text" class="form-control" id="inputEmail3" formControlName="link" placeholder="Lien LearnyBox" [ngClass]="{ 'is-invalid': submitted && f.link.errors }">
                                <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
                                    <div *ngIf="f.link.errors.required">Le champs est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
    <!-- Row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Problématiques</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <ng-multiselect-dropdown
                    [placeholder]="'Associez des problématics'"
                    [data]="problematics"
                    [(ngModel)]="selectedProblematics"
                    formControlName="selectedProblematics"
                    [settings]="dropdownSettings"
                    (onSelect)="selectProblematic($event, 'select')"
                    (onDeSelect)="selectProblematic($event, 'deselect')"
                    (onSelectAll)="selectAllProblematics($event)"
                    >
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
    <!-- Row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header bg-muted">
                    <h4 class="m-b-0 text-white">Détails</h4>
                </div>
                <hr class="m-t-0">
                <div class="card-body">
                    <div class="row">
                        <button type="button" [class]="'btn btn-rounded mr-2 ' + ((selectedTextWrapper === 1) ? 'btn-secondary' : 'btn-outline-secondary')" (click)="selectedTextWrapper = 1" [ngClass]="{ 'is-invalid': submitted && f.intro.errors }">Introduction *</button>
                        <button type="button" [class]="'btn btn-rounded mr-2 ' + ((selectedTextWrapper === 2) ? 'btn-secondary' : 'btn-outline-secondary')" (click)="selectedTextWrapper = 2" [ngClass]="{ 'is-invalid': submitted && f.description.errors }">Description *</button>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12 col-md-12" *ngIf="selectedTextWrapper === 1">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['intro']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-12 col-md-12" *ngIf="selectedTextWrapper === 2">
                            <div class="form-group">
                                <angular-editor id="editor1" [formControl]="newForm.controls['description']" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->
</form>

<div class="actionBtnWrapper bg-light">
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-rounded btn-secondary float-right" [routerLink]="'/' + model"><i class="icon-action-undo"></i> Annuler</a>
            <button class="btn btn-rounded btn-success float-right mr-3" (click)="onSubmit()"><i class="fa fa-check"></i> Valider</button>
            <button class="btn btn-rounded btn-danger float-left mr-3" (click)="delete()"><i class="icon-trash"></i> Supprimer</button>
        </div>
    </div>
</div>
