<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(assets/images/background/login-register.jpg) no-repeat center center;">
    <div class="auth-box on-sidebar m-3 m-md-0">
        <div id="loginform" [ngClass]="{'d-none': recoverform}">
            <div class="logo">
                <span class="db"><img src="assets/images/logo.jpg" alt="logo" /></span>
            </div>
            <!-- Form -->
            <div class="row">
                <div class="col-12">
                    <form class="form-horizontal m-t-20" id="loginform">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Adresse email" aria-label="Adresse email" aria-describedby="basic-addon1" #u1>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2"><i class="icon-key"></i></span>
                            </div>
                            <input type="password" class="form-control" placeholder="Mot de passe" aria-label="Mot de passe" aria-describedby="basic-addon1" #p2>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <div class="custom-control custom-checkbox d-flex">
                                    <a (click)="showRecoverForm()" href="javascript:void(0)" id="to-recover" class="text-dark ml-auto font-14"><i class="fa fa-lock m-r-5"></i> Mot de passe oublié?</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <button class="btn btn-block btn-lg btn-primary" (click)="login(u1.value, p2.value)">Connexion</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="recoverform" [ngClass]="{'d-block': recoverform}">
            <div class="logo">
                <span class="db"><img src="assets/images/logo.jpg" alt="logo" /></span>
                <span>Entrez votre adresses email. Vous recevrez les instructions à suivre.</span>
            </div>
            <div class="row m-t-20">
                <!-- Form -->
                <form class="col-12" action="index.html">
                    <!-- email -->
                    <div class="form-group row">
                        <div class="col-12">
                            <input class="form-control form-control-lg" type="email" required="" placeholder="email">
                        </div>
                    </div>
                    <!-- pwd -->
                    <div class="row m-t-20">
                        <div class="col-12">
                            <button class="btn btn-block btn-lg btn-danger" type="button" name="action">Réinitialiser</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>