import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { environment } from '../../../../environments/environment';
import { Membership } from '../../../models/memberships/membership.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-membership-detail',
  templateUrl: './membership-detail.component.html',
  styleUrls: ['./membership-detail.component.css']
})
export class MembershipDetailComponent implements OnInit {

  @ViewChild('file') file: ElementRef<HTMLElement>;
  environment = environment;
  id: any = null;
  model = "memberships";

  record: Membership;

  selectedFilePath: any;
  imageToUpload: any = null;
  newForm: FormGroup;;

  submitted = false;

  selectedTextWrapper = 1;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '200px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.apiUrl + 'content/uploadImageContent',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    
};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.initNewForm();
    this._activatedRoute.paramMap.subscribe(param => {
      this.id = param.get('id');
    });
  }

  ngOnInit() {
    this.getRecord();
  }

  getRecord() {
    if (this.id != null) {
      this.api.get(this.model + "/" + this.id).subscribe((res: Membership) => {
        this.record = res;
        this.selectedFilePath = environment.uploadPath + this.model + '/' + this.record.image;
        this.setNewForm();
      })
    } else {
      this.selectedFilePath = 'https://via.placeholder.com/512x512/C1C1C1/ffffff?text=Télécharger+image';
    }
  }

  private initNewForm() {

    this.newForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      intro: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      advantages: new FormControl('', [Validators.required]),
      bullet_points: new FormControl('', [Validators.required]),
      monthly_price: new FormControl('', [Validators.required]),
      monthly_stripe_plan: new FormControl('', [Validators.required]),
      annualy_price: new FormControl('', [Validators.required]),
      annualy_stripe_plan: new FormControl('', [Validators.required]),
    });
  }

  private setNewForm() {
    this.newForm.get('title').setValue(this.record.title);
    this.newForm.get('intro').setValue(this.record.intro);
    this.newForm.get('description').setValue(this.record.description);
    this.newForm.get('advantages').setValue(this.record.advantages);
    this.newForm.get('bullet_points').setValue(this.record.bullet_points);
    this.newForm.get('monthly_price').setValue(this.record.monthly_price);
    this.newForm.get('monthly_stripe_plan').setValue(this.record.monthly_stripe_plan);
    this.newForm.get('annualy_price').setValue(this.record.annualy_price);
    this.newForm.get('annualy_stripe_plan').setValue(this.record.annualy_stripe_plan);
  }

  readPathImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedFilePath = (<FileReader>event.target).result;
      }
      this.imageToUpload = file;
    }
  }

  get f() { return this.newForm.controls; }

  private prepareDataToSave() {
    this.submitted = true;
    const recordToSave = new FormData();
    if(this.id) recordToSave.append('id', this.id);
    recordToSave.append('title',this.f.title.value);
    recordToSave.append('intro',this.f.intro.value);
    recordToSave.append('description',this.f.description.value);
    recordToSave.append('advantages',this.f.advantages.value);
    recordToSave.append('bullet_points',this.f.bullet_points.value);
    recordToSave.append('monthly_price',this.f.monthly_price.value);
    recordToSave.append('monthly_stripe_plan',this.f.monthly_stripe_plan.value);
    recordToSave.append('annualy_price',this.f.annualy_price.value);
    recordToSave.append('annualy_stripe_plan',this.f.annualy_stripe_plan.value);
    if(this.imageToUpload) recordToSave.append('image', this.imageToUpload, this.imageToUpload.name);

    return recordToSave;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.notifier.notify('warning','Veulliez verifier les champs obligatoires');
      return;
    }
    if (this.id === null && this.imageToUpload === null) {
      this.notifier.notify('warning','Veulliez sélectionner une image');
      return;
    }
    const record = this.prepareDataToSave();
    if (this.id != null) {
      this.api.post(this.model, record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été modifié.");
        // this.router.navigate(["records"]);
      })
    } else {
      this.api.post(this.model + '/create', record).subscribe(res => {
        this.notifier.notify('success',"L'élément a bien été créé.");
        // this.router.navigate(["records"]);
      })
    }
  }

  delete() {
    this.api.delete(this.model + "/" + this.id).subscribe(res => {
      this.notifier.notify('success',"L'élément a bien été supprimé");
      this.router.navigate([this.model]);
    });
  }
}
